<template>
  <div v-loading="loading">
    <div ref="screen" class="input">
      <!--    顶部选项按钮 开始-->
      <el-row>
        <el-button type="primary" size="mini" @click="quickCheckIn.dialogQuickCheckIn = true, getQuickCheckIn(), getVacantRoom()" v-if="authe.Hotel_Core_RoomStateDiagram_ExpressCheckIn">快速入住</el-button>&nbsp;&nbsp;
          <el-dialog title="快速入住" :visible.sync="quickCheckIn.dialogQuickCheckIn" width="600px">
            <el-row style="margin-bottom: 10px" type="flex" justify="center" >
              <el-col :span="19">
                <!-- <el-input v-model="quickCheckIn.value" placeholder="请输入  保留 / 预定房间号"></el-input> -->
                <el-select v-model="quickCheckIn.selectedRoomKeep" placeholder="请输入  保留 / 预定房间号" filterable style="width: 100%" @change="quickCheckIn.chooseRoomType='', quickCheckIn.selectedRoomType=''">
                  <el-option v-for="(value,i) in quickCheckIn.keepReservationList" :key="i" :label="value.hsAddCommunity + ' ' + value.hsRoomType + ' ' + value.hsAddDoorplateno" :value="value.jsrcId + '-' + value.jsrcState + '-' + value.hsRoomType">
                    <span style="float: left">{{ value.hsAddCommunity + ' ' + value.hsRoomType + ' ' + value.hsAddDoorplateno }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ value.popTelephone }}</span>
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row style="margin-bottom: 10px" type="flex" justify="center">
              <el-col :span="6" >
                <el-select v-model="quickCheckIn.chooseRoomType" placeholder="请选择房型" @change="getVacantRoom">
                  <el-option label="请选择房型" value></el-option>
                  <el-option v-for="(value,i) in JSON.parse(setupList.jsrsuRoomType)" :key="i" :label="value.roomType" :value="value.roomType"></el-option>
                </el-select>
              </el-col>
              <el-col :span="12" :offset="1">
                <el-select v-model="quickCheckIn.selectedRoomType" placeholder="无保留 / 预约订单  请选择房间类型" style="width: 100%" @change="quickCheckIn.selectedRoomKeep = '', getSingleRoom()">
                  <el-option v-for="(value,i) in quickCheckIn.vacantRoomList" :key="i" :label="value.hsAddCommunity + '   ' + value.hsAddDoorplateno" :value="value.hsId">
                      <span style="float: left">{{ value.hsAddCommunity + '   ' + value.hsAddDoorplateno }}</span>
                      <span style="float: right; color: #8492a6 ; font-size: 13px">{{ value.hsRoomType }}</span>
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row style="margin-bottom: 20px" >
              <el-col :span="24" style="text-align: center">
                空房的数量： {{quickCheckIn.vacantRoomList.length}}
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24" style="text-align: center">
                <el-button type="primary" size="mini" @click="doQuickCheckIn()">快速入住</el-button>
              </el-col>
            </el-row>
          </el-dialog>
        <el-button type="primary" size="mini" @click="quickCheckOut.dialogQuickCheckOut = true, getQuickCheckOut()" v-if="authe.Hotel_Core_RoomStateDiagram_ExpressCheckOut">快速退房</el-button>&nbsp;&nbsp;
          <el-dialog title="快速退房" :visible.sync="quickCheckOut.dialogQuickCheckOut" @close="CloseQuickCheckOut" width="500px">
            <el-row type="flex" justify="center" style="margin: 10px 0 40px">
              <el-col :span="24" style="text-align:center">
                <el-select v-model="quickCheckOut.selectedRoom" placeholder="请输入  已住房间号" filterable style="width: 70%" @change="quickCheckIn.chooseRoomType='', quickCheckIn.selectedRoomType=''">
                  <el-option v-for="(value,i) in quickCheckOut.canCheckOut" :key="i" :label="value.hsAddCommunity + ' ' + value.hsRoomType + ' ' + value.hsAddDoorplateno" :value="value.jsrcId + '-' + value.jsrcState ">
                    <span style="float: left">{{ value.hsAddCommunity + ' ' + value.hsRoomType + ' ' + value.hsAddDoorplateno }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ value.popTelephone }}</span>
                  </el-option>
                </el-select>
                <el-button type="primary" style="margin-left: 10px" @click="QuickCheckOut">退房办理</el-button>
              </el-col>
            </el-row>
          </el-dialog>
        <el-button type="primary" size="mini" @click="getReservation" v-if="authe.Hotel_Core_RoomStateDiagram_Reservation">保留办理</el-button>&nbsp;&nbsp;
          <el-dialog title="保留办理" :visible.sync="reservation.dialogReservation" @close="closeDialogReservation" :close-on-click-modal="false" width="800px">
            <el-form inline :model="reservation.params" :rules="reservation.rules" ref="reservation" label-width="85px">
              <el-row>
                <el-form-item label="客户姓名" prop="popName">
                  <el-input v-model="reservation.params.popName" size="mini" class="screenInput"></el-input>
                </el-form-item>
                <el-form-item label="手机号码" prop="popTelephone">
                  <el-input v-model="reservation.params.popTelephone" oninput="value=value.replace(/[^\d]/g,'')" size="mini" class="screenInput"></el-input>
                </el-form-item>
                <el-form-item label="证件号码" prop="popIdcard">
                  <el-input v-model="reservation.params.popIdcard" size="mini" class="screenInput"></el-input>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="预约时间" prop="jsrcBeginTime">
                  <el-date-picker
                    v-model="reservation.params.jsrcBeginTime"
                    type="date"
                    placeholder="选择日期时间"
                    :format="`yyyy-MM-dd ${setupList.jsrsuCheckInTime}:00`"
                    :value-format="`yyyy-MM-dd ${setupList.jsrsuCheckInTime}:00`"
                    :picker-options="dateLimit"
                    @change="reservationStartChange"
                    size="mini"
                    style="width:125px">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="退房时间" prop="jsrcEndTime">
                  <el-date-picker
                    v-model="reservation.params.jsrcEndTime"
                    type="date"
                    placeholder="选择日期时间"
                    :format="`yyyy-MM-dd ${setupList.jsrsuCheckOutTime}:00`"
                    :value-format="setupList.jsrsuCheckOutTime ?`yyyy-MM-dd ${setupList.jsrsuCheckOutTime}:00`:''"
                    :picker-options="reservation.checkOutLimit"
                    @change="reservationStartChange"
                    size="mini"
                    style="width:125px">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="客房类型">
                  <el-select v-model="reservation.params.hsRoomType" @change="reservationStartChange" size="mini" class="screenInput">
                    <el-option v-for="(value,i) in JSON.parse(setupList.jsrsuRoomType)" :key="i" :label="value.roomType" :value="value.roomType"></el-option>
                  </el-select>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="备注">
                  <el-input v-model="reservation.params.jsrcRemarks" type="textarea" :row="2" size="mini" style="width: 565px"></el-input>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="套数" prop="number">
                  <el-input v-model="reservation.params.number" oninput="value=value.replace(/[^\d]/g,'')" size="mini" class="screenInput"></el-input> 共{{reservation.allEmptyRoom}}套空房
                </el-form-item>
              </el-row>
            </el-form>
            <div slot="footer">
              <el-button @click="reservation.dialogReservation = false">取 消</el-button>
              <el-button type="primary" @click="reservationSave('reservation')">确 认</el-button>
            </div>
          </el-dialog>
        <el-button type="primary" size="mini" @click="getBookingOrder">预订订单</el-button>&nbsp;&nbsp;
          <el-dialog title="预订订单" :visible.sync="bookingOrder.dialogBookingOrder" width="1000px">
            <div style="text-align:center;">
              <el-table
              :data="bookingOrder.dataList"
              @row-dblclick="openBookingOrder"
              highlight-current-row
              height="528px"
              style="width:100%;">
                <el-table-column prop="address" label="客房地址" :formatter="formatAddress" min-width="90px"></el-table-column>
                <el-table-column prop="popName" label="房客姓名" :formatter="formatPopName" min-width="50px"></el-table-column>
                <el-table-column prop="jsrcTotalDays" label="总天数" min-width="50px"></el-table-column>
                <el-table-column prop="jsrcTotalPrice" label="总价格" min-width="50px"></el-table-column>
                <el-table-column prop="jsrcDeposit" label="押金" min-width="50px"></el-table-column>
                <el-table-column prop="jsrcState" label="合约状态" min-width="50px"></el-table-column>
                <el-table-column prop="jsrcBeginTime" label="入住时间" min-width="100px"></el-table-column>
                <el-table-column prop="jsrcEndTime" label="退房时间" min-width="100px"></el-table-column>
              </el-table>
              <!--    分页 开始-->
              <el-pagination
                background layout="total, sizes, prev, pager, next, jumper"
                :page-count="bookingOrder.pages"
                :page-size="bookingOrder.params.limit"
                :total="bookingOrder.total"
                :current-page="bookingOrder.params.cursor"
                @current-change="bookingOrderCurrent"
                @size-change="bookingOrderSize"
                :page-sizes="[10,20,40]"
                style="margin-top: 5px"
              ></el-pagination>
              <!--    分页 结束-->
            </div>
          </el-dialog>
        <el-button type="primary" size="mini" @click="getCollection" v-if="authe.Hotel_Core_RoomStateDiagram_TakeOrder">取单</el-button>&nbsp;&nbsp;
          <el-dialog title="取单" v-loading="collection.loading" :visible.sync="collection.dialogCollection" @close="closeDialogCollection" :close-on-click-modal="false" width="1200px">
            <el-row>
              <el-button type="primary" @click="getCollection" size="mini" icon="el-icon-refresh" circle></el-button>
            </el-row>
            <el-table
            :data="collection.dataList"
            highlight-current-row
            height="500px"
            style="width:100%;">
              <el-table-column prop="jtoApplicant" label="申请人" min-width="50px"></el-table-column>
              <el-table-column prop="jtoAddress" label="房客地址" show-overflow-tooltip min-width="50px"></el-table-column>
              <el-table-column prop="jtoRoomType" label="房客房型" min-width="50px"></el-table-column>
              <el-table-column prop="jtoTotalHousingPrice" label="订单总价" min-width="50px"></el-table-column>
              <el-table-column prop="jtoDayPrice" label="日均房价" min-width="50px"></el-table-column>
              <el-table-column prop="jtoDiscount" label="折扣率" min-width="35px"></el-table-column>
              <el-table-column prop="jtoDiscountPrice" label="折扣后总价" min-width="50px"></el-table-column>
              <el-table-column prop="jtoAveragePrice" label="折扣后均价" min-width="50px"></el-table-column>
              <el-table-column prop="jtoRemark" label="折扣原因" min-width="50px"></el-table-column>
              <el-table-column prop="jtoStatus" label="授权状态" min-width="50px"></el-table-column>
              <el-table-column label="操作" min-width="125px">
                <template slot-scope="scope">
                  <span v-if="scope.row.jtoStatus == '同意'">
                    <el-button type="success" plain @click="orderCollection(scope.row,'取单')" size="mini">取单</el-button> |
                    <el-button type="info" @click="orderCollection(scope.row,'取消授权')" style="margin-right: 10px" plain size="mini">取消</el-button>
                  </span>
                  <span v-if="scope.row.jtoStatus == '不同意'" >
                    <el-button type="info" @click="orderCollection(scope.row,'取消挂单')" style="margin-right: 10px" plain size="mini">取消挂单</el-button> |
                    <el-button type="danger" @click="orderCollection(scope.row,'重新请求')" plain size="mini">重新请求</el-button>
                  </span>
                  <span v-if="scope.row.jtoStatus == '处理中'" >
                    <el-button type="info" @click="orderCollection(scope.row,'取消授权')" style="margin-right: 10px" plain size="mini">取消授权</el-button>
                  </span>
                </template>
              </el-table-column>
            </el-table>
            <!--
            @current-change="collectionCurrentChange"
            @row-dblclick="collectionDb"-->
          </el-dialog>
        <el-badge :is-dot="isDot" class="item">
        <el-button type="primary" size="mini" @click="openDialogMessagePush" v-if="authe.Hotel_Core_RoomStateDiagram_MessagePush">消息推送</el-button>
          <el-dialog title="消息推送" :visible.sync="messagePush.dialogMessagePush" @close="closeDialogMessagePush" :close-on-click-modal="false" width="960px">
            <el-table :data="messagePush.dataList" highlight-current-row height="419px" style="width:100%;">
              <el-table-column prop="address" label="客房地址" min-width="100px"></el-table-column>
              <el-table-column prop="jrmpMessage" label="内容" show-overflow-tooltip min-width="180px"></el-table-column>
              <el-table-column prop="jrmpTime" label="时间" min-width="100px"></el-table-column>
              <el-table-column label="处理状态" min-width="50px">
                <template slot-scope="scope">
                  <span v-if="scope.row.jrmpState == 0">
                    未处理
                  </span>
                  <span v-else-if="scope.row.jrmpState == 1" >
                    处理中
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="操作" min-width="50px">
                <template slot-scope="scope">
                  <span v-if="scope.row.jrmpState == 0">
                    <el-button type="success" plain @click="updateMessageState(scope.row.jrmpId,1)" size="mini">处理</el-button>
                  </span>
                  <span v-else-if="scope.row.jrmpState == 1" >
                    <el-button type="primary" plain @click="updateMessageState(scope.row.jrmpId,2)" size="mini">完成</el-button>
                  </span>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              layout="total, sizes, prev, pager, next, jumper"
              :total="messagePush.total"
              :page-size="messagePush.params.limit"
              :page-count="messagePush.pages"
              :current-page="messagePush.params.cursor"
              @current-change="messagePushCurrent"
              @size-change="messagePushSize"
              :page-sizes="[5,10,20,40]"
              background
              align='center'>
            </el-pagination>
          </el-dialog>
        </el-badge>&nbsp;&nbsp;
        <el-button type="primary" size="mini" @click="getDataList" icon="el-icon-refresh" circle></el-button>
      </el-row>
      <!--    顶部选项按钮 结束-->

      <!--    筛选按钮 开始-->
      <el-row class="screen" style="padding-top:5px">
        <el-radio-group @change="getDataList" class="radioGroup" v-model="data.params.screenType" size="mini">
          <el-radio-button label="所有客房" :disabled='data.sortBtnNum.allNum == 0'>所有客房 ({{data.sortBtnNum.allNum}})</el-radio-button>
          <el-radio-button label="空置干净" :disabled='data.sortBtnNum.vacantCleanNum == 0'>空置干净 ({{data.sortBtnNum.vacantCleanNum}})</el-radio-button>
          <el-radio-button label="空置保洁" :disabled="!data.sortBtnNum.vacantCleaningNum">空置保洁 ({{data.sortBtnNum.vacantCleaningNum}})</el-radio-button>
          <el-radio-button label="空置维修" :disabled="!data.sortBtnNum.vacantRepairNum">空置维修 ({{data.sortBtnNum.vacantRepairNum}})</el-radio-button>
          <el-radio-button label="当天抵达" :disabled="!data.sortBtnNum.todayArriveNum">当天抵达 ({{data.sortBtnNum.todayArriveNum}})</el-radio-button>
          <el-radio-button label="当天预离" :disabled="!data.sortBtnNum.todayLeaveNum">当天预离 ({{data.sortBtnNum.todayLeaveNum}})</el-radio-button>
          <el-radio-button label="当天预订" :disabled="!data.sortBtnNum.todayReserveNum">当天预订 ({{data.sortBtnNum.todayReserveNum}})</el-radio-button>
          <el-radio-button label="欠款客房" :disabled="!data.sortBtnNum.arrearsNum">欠款客房 ({{data.sortBtnNum.arrearsNum}})</el-radio-button>
          <el-radio-button label="预定客房" :disabled="!data.sortBtnNum.reserveNum">预定客房 ({{data.sortBtnNum.reserveNum}})</el-radio-button>
          <el-radio-button label="保留客房" :disabled="!data.sortBtnNum.retainNum">保留客房 ({{data.sortBtnNum.retainNum}})</el-radio-button>
          <el-radio-button label="普通客房" :disabled="!data.sortBtnNum.ordinaryNum">普通客房 ({{data.sortBtnNum.ordinaryNum}})</el-radio-button>
          <el-radio-button label="钟点客房" :disabled="!data.sortBtnNum.hourNum">钟点客房 ({{data.sortBtnNum.hourNum}})</el-radio-button>
        </el-radio-group>
      </el-row>
      <!--    筛选按钮 结束-->

      <!--    筛选框 开始-->
      <el-row class="screen">
        <el-form inline>
          <el-row>
            <el-form-item label="门店" label-width="35px">
              <el-input v-model="data.params.hsAddCommunity" @change="getDataList" filterable clearable size="mini" class="screenInput"></el-input>
            </el-form-item>
            <el-form-item label="客房类型" label-width="70px">
              <el-select v-model="data.params.hsRoomType" @change="getDataList" filterable clearable size="mini" class="screenInput">
                <el-option v-for="(value,i) in JSON.parse(setupList.jsrsuRoomType)" :key="i" :label="value.roomType" :value="value.roomType"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="房号" label-width="40px">
              <el-input v-model="data.params.hsAddDoorplateno" @change="getDataList" filterable clearable size="mini" class="screenInput"></el-input>
            </el-form-item>
          </el-row>
        </el-form>
      </el-row>
      <!--    筛选框 结束-->
    </div>
    <!--    列表 开始-->
    <div class="list" v-loading="listLoading">
      <div class="tabs" :style="{height: listHeight}" >
        <el-tabs v-model="data.tabs" @tab-click="tabPaneChange" tab-position="left">
          <el-tab-pane name="全部" label="全部"></el-tab-pane>
          <el-tab-pane v-for="(value,i) in floorList" :key="`${value}+${i}`" :name="value" :label="`F${value}`"></el-tab-pane>
        </el-tabs>
      </div>
      <div class="cardList" :style="{height: listHeight}" @scroll="handleScroll" id="cardlistdemo">
        <el-card class="card" :style="{background: value.cardColor}" v-for="(value,i) in testdata" :key="i" shadow="hover">
          <el-button :type="value.buttonColor" @click="addChanceTime(value)" v-if="value.diagramState == '空'" class="button" size="small">{{value.diagramState}}</el-button>
          <el-button :type="value.buttonColor" @click="orderDetails(value)" v-else class="button" size="small">{{value.diagramState}}</el-button>
          <div @click="roomDetailDblclick(value)" class="text">
            <span class="room_number" :style="{color: value.roomColor}">{{value.diagramDoorplateno}}</span>
            <div class="describe">
              <span>{{value.diagramName}}</span><br>
              <span>{{value.diagramDescribe}}</span><br>
              <span>{{value.diagramRoomType}}</span><br>
              <span>共{{value.diagramResidentiality}}人</span>
            </div>
          </div>
        </el-card>
      </div>
    </div>
    <!--    列表 结束-->

    <!--    订单 开始-->
    <el-dialog :title="`${order.params.address} 订单详情 ${order.params.jsrcState}`" :visible.sync="order.dialogOrderDetails" @close="closeDialogDetails" :close-on-click-modal="false" width="1250px" class="screen">
      <el-row>
        <el-col :span="17" style="border-right: 5px solid #e0ecff">
          <div v-if="order.params.diagramState == '住' || order.params.diagramState == '欠'">
            <el-form inline :label-width="screenLabelWidth">
              <el-row>
                <el-col :span="18">
                  <el-row>
                    <el-form-item label="客户姓名">
                      <el-input v-model="order.params.popName" disabled size="mini" class="screenInput"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号码">
                      <el-input v-model="order.params.popTelephone"  disabled size="mini" class="screenInput"></el-input>
                    </el-form-item>
                    <el-form-item label="备注">
                      <el-input v-model="order.params.popNameRemark" disabled size="mini" class="screenInput"></el-input>
                    </el-form-item>
                  </el-row>
                  <el-row>
                    <el-form-item label="证件类型">
                      <el-input v-model="order.params.popIdcardType" disabled size="mini" class="screenInput"></el-input>
                    </el-form-item>
                    <el-form-item label="证件号码">
                      <el-input v-model="order.params.popIdcard" disabled size="mini" class="screenInput"></el-input>
                    </el-form-item>
                    <el-form-item label="生日">
                      <el-input v-model="order.params.popBirth" disabled size="mini" class="screenInput"></el-input>
                    </el-form-item>
                  </el-row>
                  <el-row>
                    <el-form-item label="户籍地址">
                      <el-input v-model="order.params.popIdcardAddress" disabled size="mini" style="width:325px"></el-input>
                    </el-form-item>
                    <el-form-item label="民族">
                      <el-input v-model="order.params.popNation" disabled size="mini" class="screenInput"></el-input>
                    </el-form-item>
                  </el-row>
                  <el-row>
                    <el-form-item label="入住次数">
                      <el-input v-model="order.params.jsrrCheckInNum" disabled size="mini" class="screenInput"></el-input>
                    </el-form-item>
                    <el-form-item label="渠道类型">
                      <el-input v-model="order.params.jcuType" disabled size="mini" class="screenInput"></el-input>
                    </el-form-item>
                    <el-form-item label="群体分类">
                      <el-input v-model="order.params.jcuGroupType" disabled size="mini" class="screenInput"></el-input>
                    </el-form-item>
                  </el-row>
                </el-col>
                <el-col :span="5">
                  <div class="block">
                    <el-image :src="order.params.popImgPath" style="width:120px;height:150px;margin:7px 0 0 50px">
                      <div slot="error" class="image-slot">
                        <!-- <i class="el-icon-picture-outline"></i> -->
                        <img src="../../assets/images/userImage.png">
                      </div >
                    </el-image>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-row>
                  <el-form-item label="价格方案">
                    <el-input v-model="order.params.jppPlanName" disabled size="mini" class="screenInput"></el-input>
                  </el-form-item>
                  <el-form-item label="允许挂账">
                    <el-input v-model="order.params.jcuAllowCredit" disabled size="mini" class="screenInput"></el-input>
                  </el-form-item>
                  <el-form-item label="挂账额度">
                    <el-input v-model="order.params.jcuMaxCredit" disabled size="mini" class="screenInput"></el-input>
                  </el-form-item>
                  <el-form-item label="会员级别" v-if="order.params.jcuType == '会员'">
                    <el-input v-model="order.params.jcuMemberLevel" disabled size="mini" class="screenInput"></el-input>
                  </el-form-item>
                </el-row>
                <el-row style="margin-top: 15px">
                  <el-form-item label="预定时间">
                    <el-input v-model="order.params.jsrcBeginTime" disabled size="mini" class="screenInput"></el-input>
                  </el-form-item>
                  <el-form-item label="退房时间">
                    <el-input v-model="order.params.jsrcEndTime" disabled size="mini" class="screenInput"></el-input>
                  </el-form-item>
                  <el-form-item label="总天数">
                    <el-input v-model="order.params.jsrcTotalDays" disabled size="mini" class="screenInput"></el-input>
                  </el-form-item>
                  <el-form-item label="总房价">
                    <el-input v-model="order.params.jsrcAmountPayable" disabled size="mini" class="screenInput"></el-input>
                  </el-form-item>
                </el-row>
                <el-row>
                  <el-form-item label="日均价格">
                    <el-input v-model="order.params.jsrcDailyPrice" disabled size="mini" class="screenInput"></el-input>
                  </el-form-item>
                  <el-form-item label="客房押金">
                    <el-input v-model="order.params.jsrcDeposit" disabled size="mini" class="screenInput"></el-input>
                  </el-form-item>
                  <el-form-item label="订单来源">
                    <el-input v-model="order.params.jsrcOrderSource" disabled size="mini" class="screenInput"></el-input>
                  </el-form-item>
                  <el-form-item label="实付金额">
                    <el-input v-model="order.payable" disabled size="mini" class="screenInput"></el-input>
                  </el-form-item>
                </el-row>
                <el-row>
                  <el-form-item label="入住时间">
                    <el-input v-model="order.params.jsrcActualOccupancyTime" disabled size="mini" class="screenInput"></el-input>
                  </el-form-item>
                  <el-form-item label="退房时间">
                    <el-input v-model="order.params.jsrcEndTime" disabled size="mini" class="screenInput"></el-input>
                  </el-form-item>
                  <el-form-item label="付款方式">
                    <el-input v-model="order.params.jsrcPaymentMethod" disabled size="mini" class="screenInput"></el-input>
                  </el-form-item>
                  <el-form-item label="入住类型">
                    <el-input v-model="order.params.jsrcTypeOccupancy" disabled size="mini" class="screenInput"></el-input>
                  </el-form-item>
                </el-row>
                <el-row>
                  <el-form-item label="订单备注">
                    <el-input v-model="order.params.jsrcRemarks" type="textarea" :row="2" disabled size="mini" style="width: 725px"></el-input>
                  </el-form-item>
                </el-row>
              </el-row>
              <el-row style="margin-top: 10px">
                <el-button type="primary" @click="extendRoom" icon="el-icon-circle-plus-outline" size="mini" v-if="authe.Hotel_Core_RoomStateDiagram_LiveOweContinuedLive">续住</el-button>
                <el-button type="primary" @click="checkOutRoom" icon="el-icon-back" size="mini" v-if="authe.Hotel_Core_RoomStateDiagram_LiveOweCheckOut">办理退房</el-button>
                <el-button type="primary" @click="orderChanceRoom" icon="el-icon-refresh" size="mini" v-if="authe.Hotel_Core_RoomStateDiagram_LiveOweChangeRoom ">换房</el-button>
                <el-button type="primary" @click="followUp.dialogOrderFollowUp = true" icon="el-icon-edit-outline" size="mini" v-if="authe.Hotel_Core_RoomStateDiagram_LiveOweWriteFollowUp">写跟进</el-button>
                <!-- <el-button type="primary" @click="addDoorCard(1)" icon="el-icon-refresh" size="mini" v-if="authe.Hotel_Core_RoomStateDiagram_LiveOweAuthorization">授权管理</el-button> -->
              </el-row>
            </el-form>
            <el-table
            :data="order.dataFollowUp"
            highlight-current-row
            height="216px"
            size="mini">
              <el-table-column prop="registrantName" label="操作人" min-width="25px"></el-table-column>
              <el-table-column prop="type" label="类型" width="80px"></el-table-column>
              <el-table-column prop="text" show-overflow-tooltip label="操作记录" min-width="100px"></el-table-column>
              <el-table-column prop="time" label="操作时间" width="150px" :formatter="formatterTime"></el-table-column>
            </el-table>
          </div>
          <div v-if="order.params.diagramState == '预' || order.params.diagramState == '留' || order.params.diagramState == '空'">
            <el-form inline :model="order.params" :rules="order.rules" ref="order" :label-width="screenLabelWidth">
              <el-row>
                <el-col :span="18">
                  <el-row>
                    <el-form-item label="渠道类型">
                      <el-select v-model="order.params.jcuType" :disabled="!order.params.checked" @change="jcuTypeChange" size="mini" class="screenInput">
                      <!-- <el-select v-model="order.params.jcuType" @change="jcuTypeChange" size="mini" class="screenInput"> -->
                        <el-option label="门店" value="门店"></el-option>
                        <el-option label="会员" value="会员"></el-option>
                        <el-option label="协议单位" value="协议单位"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="群体分类">
                      <el-select v-model="order.params.jcuGroupType" :disabled="!order.params.checked" @change="jcuGroupTypeChange(order.params.jcuGroupType)" size="mini" class="screenInput">
                      <!-- <el-select v-model="order.params.jcuGroupType" @change="jcuGroupTypeChange(order.params.jcuGroupType)" size="mini" class="screenInput"> -->
                        <el-option v-for="(value,i) in order.orderChannel" :key="i" :label="value.jcuGroupType" :value="value.jcuId"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="价格方案">
                      <el-input v-model="order.params.jppPlanName" disabled size="mini" class="screenInput"></el-input>
                    </el-form-item>
                  </el-row>
                  <el-row>
                    <el-form-item label="允许挂账">
                      <el-input v-model="order.params.jcuAllowCredit" disabled size="mini" class="screenInput"></el-input>
                    </el-form-item>
                    <el-form-item label="挂账额度">
                      <el-input v-model="order.params.jcuMaxCredit" disabled size="mini" class="screenInput"></el-input>
                    </el-form-item>
                    <el-form-item label=" " v-if="order.params.jcuType == '门店' && order.checkInBtn">
                      <el-checkbox v-model="order.params.checked" @change="changeChannels(order.params.checked)">修改渠道</el-checkbox>
                    </el-form-item>
                  </el-row>
                  <el-row>
                    <!-- <el-form-item label="客户姓名" prop="popName">
                      <el-input v-model="order.params.popName" :disabled="order.readCardFlag" size="mini" class="screenInput"></el-input>
                    </el-form-item> -->
                    <el-form-item label="客户姓名">
                      <el-autocomplete
                        size="mini"
                        popper-class="my-autocomplete"
                        v-model="order.params.popName"
                        :fetch-suggestions="querySearch"
                        placeholder="请输入内容"
                        @select="getVipData"
                        class="screenInput">
                        <i
                          class="el-icon-search el-input__icon"
                          slot="suffix"
                          >
                        </i>
                        <template slot-scope="props">
                          <div class="name">{{ props.item.popName}}</div>
                          <el-tooltip placement="right-end">
                            <div slot="content" style="line-height: 28px">{{ props.item.popIdcard}}</div>
                            <span class="addr">{{ props.item.popIdcard}}</span>
                          </el-tooltip>
                        </template>
                      </el-autocomplete>
                    </el-form-item>
                    <el-form-item label="手机号码" prop="popTelephone">
                      <el-input v-model="order.params.popTelephone" @change="memberInformation(order.params.popTelephone)" :disabled="!order.checkInBtn" oninput="value=value.replace(/[^\d]/g,'')" size="mini" class="screenInput"></el-input>
                    </el-form-item>
                    <el-form-item label="备注">
                      <el-input v-model="order.params.popNameRemark" :disabled="!order.checkInBtn" size="mini" class="screenInput"></el-input>
                    </el-form-item>
                  </el-row>
                  <el-row>
                    <el-form-item label="证件类型" prop="popIdcardType">
                      <el-select v-model="order.params.popIdcardType" size="mini" class="screenInput">
                        <el-option label="身份证/临时身份证/户口本" value="身份证/临时身份证/户口本"></el-option>
                        <el-option label="回乡证/护照" value="回乡证/护照"></el-option>
                        <el-option label="其他" value="其他"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="证件号码" prop="popIdcard">
                      <el-input @blur="getBirthday()" v-model="order.params.popIdcard" :disabled="order.readCardFlag" size="mini" class="screenInput"></el-input>
                    </el-form-item>
                    <el-form-item label="生日" prop="popBirth">
                      <el-date-picker
                        v-model="order.params.popBirth"
                        type="date"
                        placeholder="选择日期时间"
                        value-format="yyyy-MM-dd"
                        :disabled="true"
                        size="mini"
                        style="width:125px">
                      </el-date-picker>
                    </el-form-item>
                  </el-row>
                </el-col>
                <el-col :span="5">
                  <div class="block">
                    <el-image :src="order.params.popImgPath" style="width:120px;height:150px;margin:7px 0 0 50px">
                      <div slot="error" class="image-slot">
                        <img src="../../assets/images/userImage.png">
                      </div >
                    </el-image>
                  </div>
                </el-col>
              </el-row>

              <el-row>
                <el-row>
                  <el-form-item label="户籍地址" prop="popIdcardAddress">
                    <el-input v-model="order.params.popIdcardAddress" :disabled="order.readCardFlag" size="mini" class="screenInput"></el-input>
                  </el-form-item>
                  <el-form-item label="民族" prop="popNation">
                    <el-input v-model="order.params.popNation" :disabled="order.readCardFlag" size="mini" class="screenInput"></el-input>
                  </el-form-item>
                  <el-form-item v-if="order.checkInBtn" style="padding-left: 25px">
                    <el-button @click="startFun" size="mini" v-if="authe.Hotel_Core_RoomStateDiagram_EmptyReadIdentityCard">读取身份证</el-button>
                    <el-button @click="addPeople" size="mini" v-if="authe.Hotel_Core_RoomStateDiagram_EmptyADDInformation">添加信息</el-button><!-- v-if="order.addPeople"-->
                  </el-form-item>
                </el-row>
                <el-row>
                  <el-form-item label="入住次数">
                    <el-input v-model="order.params.jsrrCheckInNum" disabled size="mini" style="width:90px"></el-input>
                  </el-form-item>
                  <el-form-item label="签单人挂账额度" label-width="110px">
                    <el-input v-model="order.params.jspCredit" disabled size="mini" class="screenInput"></el-input>
                  </el-form-item>
                  <el-form-item label="会员级别" v-if="order.params.jcuType == '会员'">
                    <el-input v-model="order.params.jcuMemberLevel" disabled size="mini" class="screenInput"></el-input>
                  </el-form-item>
                </el-row>
                <el-row style="margin-top: 25px">
                  <el-form-item label="入住时间" v-if="order.params.jsrcTypeOccupancy == '普通客房'">
                    <el-date-picker
                      v-model="order.params.jsrcBeginTime"
                      type="date"
                      placeholder="选择日期时间"
                      :format="`yyyy-MM-dd ${setupList.jsrsuCheckInTime}:00`"
                      :value-format="`yyyy-MM-dd ${setupList.jsrsuCheckInTime}:00`"
                      disabled
                      size="mini"
                      style="width:125px">
                    </el-date-picker>
                  </el-form-item>
                  <el-form-item label="入住时间" v-else>
                    <el-time-select
                      v-model="order.params.jsrcBeginTime"
                      placeholder="选择时间"
                      :picker-options="beginTimeOptions"
                      @change="formatBeginTime"
                      size="mini"
                      style="width:125px">
                    </el-time-select>
                  </el-form-item>
                  <el-form-item label="退房时间" prop="jsrcEndTime" v-if="order.params.jsrcTypeOccupancy == '普通客房'">
                    <el-date-picker
                      v-model="order.params.jsrcEndTime"
                      type="date"
                      :picker-options="{
                        disabledDate(time) {
                          return time.getTime() < Date.now()
                        }
                      }"
                      placeholder="选择时间"
                      :format="`yyyy-MM-dd ${setupList.jsrsuCheckOutTime}:00`"
                      :value-format="`yyyy-MM-dd ${setupList.jsrsuCheckOutTime}:00`"
                      :disabled="order.params.diagramState == '预' && !order.checkInBtn"
                      @change="calcAmount"
                      size="mini"
                      style="width:125px">
                    </el-date-picker>
                  </el-form-item>
                  <el-form-item label="退房时间" v-else>
                    <el-time-select
                      v-model="order.params.jsrcEndTime"
                      placeholder="选择时间"
                      :picker-options="endTImeOptions"
                      @change="formatEndTime"
                      size="mini"
                      style="width:125px">
                    </el-time-select>
                  </el-form-item>
                  <el-form-item label="总天数">
                    <el-input v-model="order.params.jsrcTotalDays" disabled size="mini" class="screenInput"></el-input>
                  </el-form-item>
                  <el-form-item label="入住类型" prop="jsrcTypeOccupancy">
                    <el-select v-model="order.params.jsrcTypeOccupancy" @change="selectTypeOccupancy" :disabled="!order.checkInBtn" size="mini" class="screenInput">
                      <el-option label="普通客房" value="普通客房"></el-option>
                      <el-option label="钟点客房" value="钟点客房"></el-option>
                    </el-select>
                  </el-form-item>
                </el-row>
                <el-row>
                  <el-form-item label="日均价格">
                    <el-input v-model="order.params.jsrcDailyPrice" disabled size="mini" class="screenInput"></el-input>
                  </el-form-item>
                  <el-form-item label="客房押金">
                    <el-input v-model="order.params.jsrcDeposit" disabled size="mini" class="screenInput"></el-input>
                  </el-form-item>
                  <el-form-item label="订单来源" prop="jsrcOrderSource">
                    <el-select v-model="order.params.jsrcOrderSource" :disabled="!order.checkInBtn" size="mini" class="screenInput">
                      <el-option label="上门客户" value="上门客户"></el-option>
                      <el-option label="官微订单" value="官微订单"></el-option>
                      <el-option label="携程订单" value="携程订单"></el-option>
                      <el-option label="去哪儿" value="去哪儿"></el-option>
                      <el-option label="住哪网" value="住哪网"></el-option>
                      <el-option label="美团网" value="美团网"></el-option>
                      <el-option label="飞猪网" value="飞猪网"></el-option>
                      <el-option label="途牛网" value="途牛网"></el-option>
                      <el-option label="驴妈妈" value="驴妈妈"></el-option>
                      <el-option label="爱彼迎" value="爱彼迎"></el-option>
                      <el-option label="途家网" value="途家网"></el-option>
                      <el-option label="游天下" value="游天下"></el-option>
                      <el-option label="全球名宿" value="全球名宿"></el-option>
                      <el-option label="榛果民宿" value="榛果民宿"></el-option>
                      <el-option label="小猪短租" value="小猪短租"></el-option>
                      <el-option label="蚂蚁短租" value="蚂蚁短租"></el-option>
                      <el-option label="木鸟短租" value="木鸟短租"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="已付金额">
                    <el-input v-model="order.params.jsrcFirstPay" disabled size="mini" class="screenInput"></el-input>
                  </el-form-item>
                </el-row>
                <el-row>
                  <el-form-item label="总房价">
                    <el-input v-model="order.params.jsrcAmountPayable" disabled size="mini" class="screenInput"></el-input>
                  </el-form-item>
                  <el-form-item label="应付金额">
                    <el-input v-model="order.payable" disabled size="mini" class="screenInput"></el-input>
                  </el-form-item>
                  <el-form-item label="折后金额">
                    <el-input v-model="order.params.jsrcTotalPrice" disabled size="mini" class="screenInput" style="margin-right: 13px"></el-input>
                  </el-form-item>
                  <el-form-item v-if="order.discountApplication">
                    <el-button @click="addDiscount" size="mini" v-if="authe.Hotel_Core_RoomStateDiagram_AdvanceStayDiscountApplication">折扣申请</el-button>
                  </el-form-item>
                </el-row>
                <el-row>
                  <el-form-item label="订单备注">
                    <el-input v-model="order.params.jsrcRemarks" type="textarea" :row="2" size="mini" style="width: 725px"></el-input>
                  </el-form-item>
                </el-row>
              </el-row>
              <!-- <div>
                1111
                  <el-form-item label="客户姓名">
                    <el-input v-model="order.params.popName" disabled size="mini" class="screenInput"></el-input>
                  </el-form-item>
                  <el-form-item label="客户姓名">
                    <el-autocomplete
                      size="mini"
                      popper-class="my-autocomplete"
                      v-model="order.params.popName"
                      :fetch-suggestions="querySearch"
                      placeholder="请输入内容"
                      @select="demo">
                      <i
                        class="el-icon-edit el-input__icon"
                        slot="suffix"
                        >
                      </i>
                      <template slot-scope="props" >
                        <div class="name">{{ props.item.popName}}</div>
                        <span class="addr">{{ props.item.popIdcard}}</span>
                      </template>
                    </el-autocomplete>
                  </el-form-item>
              </div> -->

            </el-form>
          </div>
        </el-col>
        <el-col :span="7">
          <div v-if="order.params.diagramState == '住' || order.params.diagramState == '欠'">
            <el-table
            :data="order.params.popTmpPARMS"
            @row-click="popNameChange"
            highlight-current-row
            ref="fellowResidents"
            height="143px"
            size="mini">
              <el-table-column prop="popName" label="同住人姓名" min-width="50px"></el-table-column>
              <el-table-column prop="popIdcard" label="证件号码" min-width="50px"></el-table-column>
            </el-table>
            <div class="rightButtonList">
              <el-popover
                placement="bottom-start"
                width="200"
                trigger="hover"
                v-for="(value,i) in order.deviceInfo"
                :key="i">
                <div v-html="value.html" style="font-size:14px"></div>
                <el-button slot="reference" plain class="btn">
                  <el-image :src="value.url" style="width: 65px;"></el-image>
                </el-button>
              </el-popover>
            </div>
            <p style="font-size:30px;margin: 0 0 1px 75px">客户提醒服务</p>
            <el-table
            :data="order.dataRemind"
            highlight-current-row
            height="283px"
            size="mini">
              <el-table-column prop="time" label="提醒时间" min-width="50px"></el-table-column>
              <el-table-column prop="text" show-overflow-tooltip label="提醒内容" min-width="50px"></el-table-column>
              <el-table-column prop="state" label="状态" min-width="20px"></el-table-column>
            </el-table>
          </div>
          <div v-if="order.params.diagramState == '预' || order.params.diagramState == '留' || order.params.diagramState == '空'">
            <div v-if="!order.checkInBtn && (order.params.diagramState == '预' || order.params.diagramState == '留')">
              <el-form :label-width="screenLabelWidth">
                <el-form-item label="预订人">
                  <el-input v-model="order.reserve.popName" disabled size="mini" style="width:250px"></el-input>
                </el-form-item>
                <el-form-item label="预订电话">
                  <el-input v-model="order.reserve.popTelephone" disabled size="mini" style="width:250px"></el-input>
                </el-form-item>
                <el-form-item label="预订途径">
                  <el-input v-model="order.reserve.jsrcBookingWay" disabled size="mini" style="width:250px"></el-input>
                </el-form-item>
              </el-form>
              <el-table
              :data="order.dataFollowUp"
              highlight-current-row
              height="319px"
              size="mini">
                <el-table-column prop="registrantName" label="操作人" min-width="30px"></el-table-column>
                <el-table-column prop="type" label="类型" min-width="35px"></el-table-column>
                <el-table-column prop="text" label="操作记录" show-overflow-tooltip min-width="70px"></el-table-column>
                <el-table-column prop="time" label="操作时间" :formatter="formatterTime" show-overflow-tooltip min-width="40px"></el-table-column>
              </el-table>
            </div>
            <div v-else>
              <el-table
              :data="order.params.popTmpPARMS"
              @row-click="popNameChange"
              highlight-current-row
              ref="fellowResidents"
              height="170px"
              size="mini">
                <el-table-column prop="popName" label="同住人姓名" min-width="50px"></el-table-column>
                <el-table-column prop="popIdcard" label="证件号码" min-width="50px"></el-table-column>
              </el-table>
              <el-row>
                <el-col :span="14">
                  <el-row>
                    <p>实付金额</p><br>
                    <p style="font-size: 50px">{{order.params.jsrcTotalPrice}}</p>
                  </el-row>
                  <el-row v-if="order.params.jsrcTotalPrice != 0 && order.params.jcuAllowCredit == '是'">
                    <p>挂账应付</p><br>
                    <p style="font-size: 50px">{{order.params.jsrcAmountPayable}}</p>
                  </el-row>
                </el-col>
                <el-col :span="10">
                  <el-form v-if="authe.Hotel_Core_RoomStateDiagram_AdvanceStayEmptyCollection">
                    <el-form-item v-if="order.params.jsrcTotalPrice != 0" style="margin-top: 10px">
                      <el-button type="success" @click="checkInReceivables(1,'checkin')">现金收银</el-button>
                    </el-form-item>
                    <el-form-item v-if="order.params.jsrcTotalPrice != 0" style="margin-top: 10px">
                      <el-button type="success" @click="checkInReceivables(2,'checkin')">扫码收银</el-button>
                    </el-form-item>
                    <el-form-item v-if="order.params.jsrcTotalPrice != 0" style="margin-top: 10px">
                      <el-button type="success" @click="checkInReceivables(3,'checkin')">台卡收银</el-button>
                    </el-form-item>
                    <el-form-item v-if="order.params.jsrcTotalPrice != 0 && order.params.jcuAllowCredit == '是'" style="margin-top: 10px">
                      <el-button type="success" @click="checkInReceivables(4,'checkin')">挂账入住</el-button>
                    </el-form-item>
                    <el-form-item v-if="order.params.jsrcTotalPrice == 0" style="margin-top: 10px">
                      <el-button type="success" @click="checkInReceivables(5,'checkin')">办理入住</el-button>
                    </el-form-item>
                  </el-form>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
      <div slot="footer" v-if="order.params.diagramState == '预' || order.params.diagramState == '留' || order.params.diagramState == '空'">
        <el-button type="primary" @click="checkInBtn" v-if="((order.params.diagramState == '预' || order.params.diagramState == '留') && order.params.diagramOrderState == 1) && authe.Hotel_Core_RoomStateDiagram_AdvanceStayCheckIn" icon="el-icon-right">入 住</el-button>
        <el-button type="primary" @click="orderReceipt" v-if="(order.params.diagramState == '预' && order.params.diagramOrderState == 0) && authe.Hotel_Core_RoomStateDiagram_AdvanceOrders" icon="el-icon-check">接 单</el-button>
        <el-button type="primary" @click="cancelRetain" v-if="order.params.diagramState == '留' && authe.Hotel_Core_RoomStateDiagram_StayCancelReservation" icon="el-icon-circle-close">取消保留</el-button>
        <el-button type="primary" @click="cancelReserve" v-if="order.params.diagramState == '预' && authe.Hotel_Core_RoomStateDiagram_AdvanceRetreat" icon="el-icon-back">退定</el-button>
        <el-button type="primary" @click="orderChanceRoom" v-if="order.params.diagramState == '预' && authe.Hotel_Core_RoomStateDiagram_AdvanceStayChangeRoom" icon="el-icon-refresh">换房</el-button>
        <el-button type="primary" @click="followUp.dialogOrderFollowUp = true" v-if="order.params.diagramState != '空' && authe.Hotel_Core_RoomStateDiagram_AdvanceStayWriteFollowUp" icon="el-icon-edit-outline">写跟进</el-button>
        <el-button type="primary" @click="grantDoorCard" v-if="(order.checkInBtn || order.params.diagramState == '住') && authe.Hotel_Core_RoomStateDiagram_AdvanceStayEmptyAuthorization">授权管理</el-button>
      </div>
    </el-dialog>
    <!--    订单 结束-->
    <!--    入住选择时间 开始-->
    <el-dialog title="选择时间" :visible.sync="chanceTime.dialogChanceTime" @close="closeDialogChanceTime" :close-on-click-modal="false" width="550px">
      <el-form inline :model="chanceTime" :rules="chanceTime.rules" ref="chanceTime" label-width="85px">
        <el-form-item label="入住时间" prop="start">
          <el-date-picker
            v-model="chanceTime.start"
            type="date"
            placeholder="选择日期时间"
            :format="`yyyy-MM-dd ${setupList.jsrsuCheckInTime}:00`"
            :value-format="`yyyy-MM-dd ${setupList.jsrsuCheckInTime}:00`"
            :picker-options="dateLimit"
            @change="chanceTimeStartChange"
            size="mini"
            style="width:125px">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="退房时间" prop="end">
          <el-date-picker
            @change="chanceTimeEndChange"
            v-model="chanceTime.end"
            type="date"
            placeholder="选择日期时间" 
            :format="`yyyy-MM-dd ${setupList.jsrsuCheckOutTime}:00`"
            :value-format="`yyyy-MM-dd ${setupList.jsrsuCheckOutTime}:00`"
            :picker-options="chanceTime.checkOutLimit"
            size="mini"
            style="width:125px">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="quickSelection(1)" style="float: left">一晚</el-button>
        <el-button @click="quickSelection(3)" style="float: left">三晚</el-button>
        <el-button @click="quickSelection(7)" style="float: left">一周</el-button>
        <el-button @click="chanceTime.dialogChanceTime = false">取 消</el-button>
        <el-button type="primary" @click="chanceTimeSave">确 认</el-button>
      </div>
    </el-dialog>
    <!--    入住选择时间 结束-->

    <!--    保留 开始-->
    <el-dialog title="保留订单" :visible.sync="retain.dialogRetainOrder" @close="closeDialogRetainOrder" :close-on-click-modal="false" width="700px">
      <el-form inline :model="retain.shortRentContractPARM" :rules="retain.rules" ref="retain" label-width="85px">
        <el-row>
          <el-form-item label="渠道类型">
            <el-select v-model="order.params.jcuType" :disabled="!order.params.checked" @change="jcuTypeChange" size="mini" class="screenInput">
              <el-option label="门店" value="门店"></el-option>
              <el-option label="会员" value="会员"></el-option>
              <el-option label="协议单位" value="协议单位"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="群体分类">
            <el-select v-model="order.params.jcuGroupType" :disabled="!order.params.checked" @change="jcuGroupTypeChange(order.params.jcuGroupType)" size="mini" class="screenInput">
              <el-option v-for="(value,i) in order.orderChannel" :key="i" :label="value.jcuGroupType" :value="value.jcuId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="价格方案">
            <el-input v-model="order.params.jppPlanName" disabled size="mini" class="screenInput"></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="允许挂账">
            <el-input v-model="order.params.jcuAllowCredit" disabled size="mini" class="screenInput"></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="客户姓名" prop="popName">
            <el-input v-model="retain.shortRentContractPARM.popName" size="mini" class="screenInput"></el-input>
          </el-form-item>
          <el-form-item label="手机号码" prop="popTelephone">
            <el-input v-model="retain.shortRentContractPARM.popTelephone" size="mini" class="screenInput"></el-input>
          </el-form-item>
          <el-form-item label="证件号码">
            <el-input v-model="retain.shortRentContractPARM.popIdcard" size="mini" class="screenInput"></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="入住时间">
            <el-date-picker
              v-model="retain.shortRentContractPARM.jsrcBeginTime"
              type="date"
              placeholder="选择日期时间"
              :format="`yyyy-MM-dd ${setupList.jsrsuCheckInTime}:00`"
              :value-format="`yyyy-MM-dd ${setupList.jsrsuCheckInTime}:00`"
              disabled
              size="mini"
              style="width:125px">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="退房时间">
            <el-date-picker
              v-model="retain.shortRentContractPARM.jsrcEndTime"
              type="date"
              placeholder="选择日期时间"
              :format="`yyyy-MM-dd ${setupList.jsrsuCheckOutTime}:00`"
              :value-format="`yyyy-MM-dd ${setupList.jsrsuCheckOutTime}:00`"
              disabled
              size="mini"
              style="width:125px">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="总天数">
            <el-input v-model="order.params.jsrcTotalDays" disabled size="mini" class="screenInput"></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="日均价格">
            <el-input v-model="order.params.jsrcDailyPrice" disabled size="mini" class="screenInput"></el-input>
          </el-form-item>
          <el-form-item label="总房价">
            <el-input v-model="order.params.jsrcAmountPayable" disabled size="mini" class="screenInput"></el-input>
          </el-form-item>
          <el-form-item label="实付金额">
            <el-input v-model="order.params.jsrcTotalPrice" disabled size="mini" class="screenInput"></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="备注">
            <el-input v-model="retain.shortRentContractPARM.jsrcRemarks" type="textarea" :row="2" size="mini" style="width: 565px"></el-input>
          </el-form-item>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="retain.dialogRetainOrder = false">取 消</el-button>
        <el-button type="primary" @click="retainOrderSave">确 认</el-button>
      </div>
    </el-dialog>
    <!--    保留 结束-->

    <!--    客房 开始-->
      <room-detail
        v-if="roomDetailDialog"
        :isDialogShow="roomDetailDialog"
        :isRowData="roomDetailRowData"
        :isPageName="'RoomStateDiagram'"
        :datas="repairbutton"
        @close="roomDetailClose"
      >
      </room-detail>
    <!--    客房 结束-->

    <!--   续住 开始-->
    <el-dialog :title="`${order.params.address} 续住`" :visible.sync="extend.dialogExtend" @close="closeDialogExtend" :close-on-click-modal="false" width="550px">
      <el-form inline :model="extend.params" :rules="extend.rules" ref="extend" label-width="85px">
        <el-row>
          <el-form-item label="退房时间">
            <el-input v-model="extend.checkOutTime" disabled size="mini" style="width: 145px"></el-input>
          </el-form-item>
          <el-form-item label="新退房时间" prop="newEndTime">
            <el-date-picker
              v-model="extend.params.newEndTime"
              type="date"
              placeholder="选择日期时间"
              :format="`yyyy-MM-dd ${setupList.jsrsuCheckOutTime}:00`"
              value-format="yyyy-MM-dd"
              :picker-options="extend.extendLimit"
              @change="newEndTimeChange"
              clearable
              size="mini"
              style="width: 175px">
            </el-date-picker>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="总共天数">
            <el-input v-model="extend.params.newTotalDay" disabled size="mini" style="width: 145px"></el-input>
          </el-form-item>
          <el-form-item label="日均价格">
            <el-input v-model="extend.params.dailyPrice" disabled size="mini" style="width: 175px"></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="客房费用">
            <el-input v-model="extend.params.payableMoney" disabled size="mini" style="width: 145px"></el-input>
          </el-form-item>
          <el-form-item label="欠费金额">
            <el-input v-model="extend.params.arrears" disabled size="mini" style="width: 175px"></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="应付金额">
            <el-input v-model="extend.params.payableMoney" disabled size="mini" style="width: 145px"></el-input>
          </el-form-item>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="putReceivables(1,'extend')" type="success">现金收银</el-button>
        <el-button @click="putReceivables(2,'extend')" type="success">扫码收银</el-button>
        <el-button @click="putReceivables(3,'extend')" type="success">台卡收银</el-button>
      </div>
    </el-dialog>
    <!--   续住 结束-->

    <!--   退房 开始-->
    <el-dialog :title="`${order.params.address} 退房`" :visible.sync="checkOut.dialogCheckOutRoom" @close="closeDialogCheckOutRoom" :close-on-click-modal="false" width="670px">
      <el-form inline :model="checkOut" :rules="checkOut.rules" ref="checkOut" :label-width="screenLabelWidth">
        <el-row>
          <el-form-item label="保洁员" prop="cleaning">
            <el-cascader
              :options="userNameList"
              v-model="checkOut.cleaning"
              @change="checkOutCleaningChange"
              :show-all-levels="false"
              :props="{ expandTrigger: 'hover' }"
              clearable
              size="mini"
              style="width:125px">
            </el-cascader>
          </el-form-item>
        </el-row>
        <el-row style="margin-top:15px;">
          <el-form-item label="附加消费">
            <el-select v-model="checkOut.consumption" clearable @change="consumptionChange" size="mini" class="screenInput">
              <el-option v-for="(value,i) in JSON.parse(setupList.jsrsuServiceCharge)" :key="i" :label="value.popservice" :value="value.popservice"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="附加单价">
            <el-input v-model="checkOut.unitPrice" disabled size="mini" class="screenInput"></el-input>
          </el-form-item>
          <el-form-item label=" ">
            <el-button @click="addService" size="mini">添加消费</el-button>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="欠费金额">
            <el-input v-model="checkOut.params.amountOfArrears" disabled size="mini" class="screenInput"></el-input>
          </el-form-item>
          <el-form-item label="附加金额">
            <el-input v-model="checkOut.params.additionalSum" disabled size="mini" class="screenInput"></el-input>
          </el-form-item>
          <el-form-item label="消费金额">
            <el-input v-model="checkOut.consumptionAmount" disabled size="mini" class="screenInput"></el-input>
          </el-form-item>
        </el-row>
        <el-row style="margin-top:15px;">
          <el-form-item label="客房押金">
            <el-input v-model="checkOut.params.checkOutDeposit" disabled size="mini" class="screenInput"></el-input>
          </el-form-item>
          <el-form-item v-if="roomrate"  label="应退房费">
            <el-input v-model="checkOut.params.refundedRentMoney" disabled size="mini" class="screenInput"></el-input>
          </el-form-item>
          <el-form-item class="paymoney" v-if="!roomrate" label="应付房费">
            <el-input  v-model="checkOut.params.absrefundedRentMoney" disabled size="mini" class="screenInput"></el-input>
          </el-form-item>
          <el-form-item v-if="money" label="应退金额">
            <el-input v-model="checkOut.params.refundedMoney" disabled size="mini" class="screenInput"></el-input>
          </el-form-item>
          <el-form-item class="paymoney" v-if="!money" label="应付金额">
            <el-input  v-model="checkOut.params.absrefundedMoney" disabled size="mini" class="screenInput"></el-input>
          </el-form-item>
        </el-row>
      </el-form>
      <el-table
        :data="checkOut.dataTable"
        highlight-current-row
        height="216px"
        size="mini">
          <el-table-column prop="popservice" label="服务" min-width="100px"></el-table-column>
          <el-table-column prop="popcharge" label="金额" min-width="100px"></el-table-column>
          <el-table-column label="删除" min-width="100px">
            <template slot-scope="scope">
              <el-button type="danger" @click="deleteService(scope.$index)" icon="el-icon-delete" plain size="mini">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      <div slot="footer">
        <span v-if="(checkOut.params.checkOutDeposit + checkOut.params.refundedRentMoney - checkOut.consumptionAmount) >= 0">
          <el-button type="success" @click="checkOut456(4,'checkOut')" v-if="order.dataOrder.jsrcPaymentMethod == '在线支付' || order.dataOrder.jsrcPaymentMethod == '扫码收款'">原路返还</el-button>
          <el-button type="success" @click="checkOut456(5,'checkOut')" v-if="order.dataOrder.jsrcPaymentMethod != '挂账消费'">现金退款</el-button>
        </span>
        <span v-else>
          <el-button type="success" @click="putReceivables(1,'checkOut')">现金收银</el-button>
          <el-button type="success" @click="putReceivables(2,'checkOut')">扫码收银</el-button>
          <el-button type="success" @click="putReceivables(3,'checkOut')">台卡收银</el-button>
        </span>
        <el-popover v-model="checkOut.popoverCheckOut" @after-leave="closePopoverCheckOut" placement="top">
          <el-form label-width="75px" :model="checkOut.params" :rules="checkOut.rules" ref="checkOutForm">
            <el-form-item label="密码" prop="password" v-if="checkOut.popoverCheckOut">
              <el-input v-model="checkOut.params.password" clearable type="password" size="mini" style="width: 125px"></el-input>
            </el-form-item>
          </el-form>
          <div style="text-align: right; margin: 0">
            <el-button @click="checkOut.popoverCheckOut = false" size="mini">取消</el-button>
            <el-button type="primary" size="mini" @click="checkOut456(6,'checkOut')">确定</el-button>
          </div>
          <el-button slot="reference" type="success" @click="checkOutConfirm" v-if="order.dataOrder.jsrcPaymentMethod == '挂账消费'">挂账退房</el-button>
        </el-popover>
      </div>
    </el-dialog>
    <!--   退房 结束-->

    <!--   换房 开始-->
    <el-dialog :title="`${order.params.address} 换房`" :visible.sync="chanceRoom.dialogOrderChanceRoom" @close="closeDialogOrderChanceRoom" :close-on-click-modal="false" width="560px">
      <el-form inline :model="chanceRoom.params" :rules="chanceRoom.rules" ref="chanceRoom" :label-width="screenLabelWidth">
        <el-row>
          <el-form-item label="现住客房">
            <el-input v-model="order.params.address" disabled size="mini" style="width:225px"></el-input>
          </el-form-item>
          <el-form-item label="换房原因">
            <el-input v-model="chanceRoom.params.reason" size="mini" class="screenInput"></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="可选房型">
            <el-select v-model="chanceRoom.roomType" @change="roomTypeChange" size="mini" class="screenInput">
              <el-option v-for="(value,i) in JSON.parse(setupList.jsrsuRoomType)" :key="i" :label="value.roomType" :value="value.roomType"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="可换客房" prop="hsId">
            <el-select v-model="chanceRoom.params.hsId" @change="checkRoomsChange" size="mini" style="width:225px">
              <el-option v-for="(value,i) in order.dataVacantRoom" :key="i" :label="value.address" :value="value.hsId"></el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="保洁人员" prop="user">
            <el-cascader
              :options="userNameList"
              v-model="chanceRoom.params.user"
              @change="chanceRoomCleaningChange"
              :show-all-levels="false"
              :props="{ expandTrigger: 'hover' }"
              clearable
              class="screenInput"
              size="mini"
              style="width:125px">
            </el-cascader>
          </el-form-item>
          <el-form-item label="保洁时间" prop="cleanRepHopeTime">
            <el-date-picker
              v-model="chanceRoom.params.cleanRepHopeTime"
              type="datetime"
              placeholder="选择日期时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              :picker-options="dateLimit"
              clearable
              size="mini"
              style="width: 225px">
            </el-date-picker>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="补房差价">
            <el-input v-model="chanceRoom.params.changeHouseMoney" disabled size="mini" class="screenInput"></el-input>
          </el-form-item>
        </el-row>
      </el-form>
      <div slot="footer">
        <span v-if="chanceRoom.params.changeHouseMoney == 0">
          <el-button @click="chanceRoom.dialogOrderChanceRoom = false">取 消</el-button>
          <el-button type="primary" @click="orderChanceRoomSave">保 存</el-button>
        </span>
        <span v-if="chanceRoom.params.changeHouseMoney > 0">
          <el-button @click="putReceivables(1,'chanceRoom')" type="success">现金收银</el-button>
          <el-button @click="putReceivables(2,'chanceRoom')" type="success">扫码收银</el-button>
          <el-button @click="putReceivables(3,'chanceRoom')" type="success">台卡收银</el-button>
        </span>
      </div>
    </el-dialog>
    <!--   换房 结束-->

    <!--   写跟进 开始-->
    <el-dialog :title="`${order.params.address} 写跟进`" :visible.sync="followUp.dialogOrderFollowUp" @close="closeDialogOrderFollowUp" :close-on-click-modal="false" width="500px">
      <el-form :model="followUp.params" :rules="followUp.rules" ref="followUp" :label-width="screenLabelWidth">
        <el-form-item label="类型">
          <el-select v-model="followUp.params.followUpType" @change="followUpTypeChange" size="mini" class="screenInput">
            <el-option label="手动跟进" value="手动跟进"></el-option>
            <el-option label="客服提醒" value="客服提醒" v-if="order.params.diagramState == '住' || order.params.diagramState == '欠'"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="提醒时间" v-if="followUp.params.followUpType == '客服提醒'" prop="reminderTime">
          <el-date-picker
            v-model="followUp.params.reminderTime"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            :picker-options="dateLimit"
            clearable
            size="mini"
            class="screenInput">
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="followUp.title" prop="record">
          <el-input v-model="followUp.params.record" type="textarea" :row="4" size="mini" style="width: 300px"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="followUp.dialogOrderFollowUp = false">取 消</el-button>
        <el-button type="primary" @click="orderFollowUpSave">保 存</el-button>
      </div>
    </el-dialog>
    <!--   写跟进 结束-->

    <!--   收银 开始-->
    <el-dialog :title="receivables.title" :visible.sync="receivables.dialogReceivables" v-loading="receivables.loading" @close="cleanReceivables" :close-on-click-modal="false" width="400px" class="normal">
      <el-form :model="receivables" :rules="receivables.rules" ref="receivables" :label-width="screenLabelWidth">
        <el-form-item label="应收">
          <el-input v-model="receivables.receivable" :readonly="receivables.state == 3" :disabled="receivables.state == 1 || receivables.state == 2" @keyup.enter.native="receivablesSave(3)" ref="receivable" onkeypress="if(event.keyCode == 13) return false;" style="width:275px"></el-input>
        </el-form-item>
        <el-form-item label="付款码" v-if="receivables.state == 2" prop="paymentCode">
          <el-input v-model="receivables.paymentCode" @keyup.enter.native="receivablesSave(2)" ref="paymentCode" onkeypress="if(event.keyCode == 13) return false;" oninput="value=value.replace(/[^\d]/g,'')" style="width:275px"></el-input>
        </el-form-item>
        <el-form-item label="实收" v-if="receivables.state == 1" prop="netReceipts">
          <el-input v-model="receivables.netReceipts" @keyup.enter.native="receivablesSave(1)" @input="netReceiptsChange" ref="netReceipts" oninput="if(isNaN(value)) { value = value.replace(/[^\d^\.]/g,'') } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)} if(value.indexOf('.',value.indexOf('.')+1)!=-1){value=value.slice(0,value.indexOf('.')+1)}" style="width:275px"></el-input>
        </el-form-item>
        <el-alert
          v-show="receivables.dialogAlert"
          style="margin-bottom: 20px"
          title="警告"
          type="warning"
          description="当前为“现金收银”模式，请检查收款金额是否正确。如需进入“扫码收银”模式请按F9。"
          show-icon>
        </el-alert>
        <el-form-item label="找零" v-if="receivables.state == 1">
          <el-input v-model="receivables.giveChange" disabled style="width:275px"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" style="text-align:center">
        <el-button v-if="receivables.state == 1" @click="receivablesSave(1)" type="success">现金收银</el-button>
        <el-button v-if="receivables.state == 2" @click="receivablesSave(2)" type="success">扫码收银</el-button>
        <el-button v-if="receivables.state == 3" @click="receivablesSave(3)" type="success">台卡收银</el-button>
      </div>
    </el-dialog>
    <!--   收银 结束-->

    <!--   折扣申请 开始-->
    <el-dialog title="折扣申请" :visible.sync="discount.dialogDiscount" @close="closeDialogDiscount" :close-on-click-modal="false" width="670px">
      <el-form inline :model="discount.temporaryOrderPARM" :rules="discount.rules" ref="discount" :label-width="screenLabelWidth">
        <el-row>
          <!-- <el-form-item label="应付金额">
            <el-input v-model="order.payable" disabled size="mini" class="screenInput"></el-input>
          </el-form-item> -->
          <el-form-item label="应付金额">
            <el-input v-model="order.params.jsrcAmountPayable" disabled size="mini" class="screenInput"></el-input>
          </el-form-item>
          <el-form-item label="折扣比例" prop="jtoDiscount">
            <el-input v-model="discount.temporaryOrderPARM.jtoDiscount" @change="jtoDiscountChange" placeholder="1-10之间的折扣率" size="mini" class="screenInput"></el-input>
          </el-form-item>
          <el-form-item label="折后金额">
            <el-input v-model="discount.temporaryOrderPARM.jtoDiscountPrice" oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}" disabled size="mini" class="screenInput"></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="授权人" prop="cleaning">
            <el-cascader
              :options="userNameList"
              v-model="discount.temporaryOrderPARM.cleaning"
              @change="discountCleaningChange"
              :show-all-levels="false"
              :props="{ expandTrigger: 'hover' }"
              clearable
              size="mini"
              style="width:125px">
            </el-cascader>
          </el-form-item>
          <el-form-item label="授权方式">
            <el-select v-model="discount.grantMode" @change="grantModeChange" size="mini" class="screenInput">
              <el-option label="现场授权" value="现场授权"></el-option>
              <el-option label="授权卡" value="授权卡"></el-option>
              <el-option label="远程授权" value="远程授权"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="折扣原因" v-if="discount.grantMode == '远程授权'" prop="jtoRemark">
            <el-input v-model="discount.temporaryOrderPARM.jtoRemark" size="mini" class="screenInput"></el-input>
          </el-form-item>
          <el-form-item label="授权密码" v-else prop="password">
            <el-input v-model="discount.temporaryOrderPARM.password" show-password size="mini" class="screenInput"></el-input>
          </el-form-item>
        </el-row>
      </el-form>
      <div slot="footer" style="text-align:center">
        <el-button @click="discount.dialogDiscount = false">取 消</el-button>
        <el-button type="primary" @click="addDiscountSave">确 认</el-button>
      </div>
    </el-dialog>
    <!--   折扣申请 结束-->

    <!--    门卡授权窗口 开始-->
    <el-dialog title="门卡授权" v-loading="doorCard.loading" :visible.sync="doorCard.dialogDoorCard" @close="closeDialogDoorCard" append-to-body width="470px">
      <el-form inline :model="doorCard.doorCardPARM" :rules="doorCard.rules" ref="doorCard" label-width="63px">
        <el-form-item label="选择门锁">
          <el-radio v-model="doorCard.checkDevice" v-for="(doorLock, i) in doorCard.doorLocks" :key="i" :label="doorLock">{{doorLock.devNickname}}</el-radio>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="房客" label-width="45px"> <!-- v-if="doorCard.checkDevice.devSecondType == 58"-->
              <el-select v-model="doorCard.doorCardPARM.popIndex" size="mini" style="width: 135px" allow-create filterable clearable>
                <el-option v-for="(item, index) in order.params.popTmpPARMS" :key="index" :label="item.popName" :value="index"></el-option> <!--v-for="" :key="" :label="" :value=""-->
              </el-select>
            </el-form-item>
            <el-form-item prop="jdcCardId" label="授权" label-width="45px" v-if="doorCard.checkDevice.devSecondType != 58">
              <el-input v-model="doorCard.doorCardPARM.jdcCardId" type="password" size="mini" style="width: 135px" placeholder="请使用读卡器读取"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="编号" label-width="45px">
              <el-input v-model="doorCard.doorCardPARM.jdcCardNum" size="mini" style="width: 135px"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="doorCard.dialogDoorCard = false">取 消</el-button>
        <el-button type="primary" v-if="doorCard.checkDevice.devSecondType == 58" @click="makeCard">确 定</el-button>
        <el-button type="primary" v-else @click="doorCardSave">确 定</el-button>
      </div>
    </el-dialog>
    <!--    门卡授权窗口 结束-->

    <!--    打印票据弹窗 开始-->
    <el-dialog title="打印票据" :visible.sync="printedHistory.dialogprintedHistory" append-to-body :close-on-click-modal="false" width="460px"  class="screen printhistory" @close="printedHistory.ticketInformation = {}">
      <div id="print" style="width: 99%; height:99%;">
        <div id="body" style="width:250px;margin: 0 auto" >
          <div class="other_fee" style="heigth: 30%">
            <div class="title" >
              <div v-if="printedHistory.showState" style="font-size: 22px;text-align: center;font-size: 700">{{printedHistory.ticketInformation.jsrsuWxgzhTitle}}<br />入住单</div>
              <div v-else style="font-size: 20px;text-align: center;font-size: 700">{{printedHistory.ticketInformation.jsrsuWxgzhTitle}}<br />退房单</div>
              <table style="width: 200px;table-layout:fixed; font-size: 14px;border-bottom: 1px solid #DADADA !important">
                <tbody style="font-weight: 400;">
                  <tr>
                    <td style="width: 250px " >订单号/No {{printedHistory.ticketInformation.saleNo}}</td>
                  </tr>
                  <tr>
                    <td style="width: 250px " >入住/Arrival {{printedHistory.ticketInformation.arrival}}</td>
                  </tr>
                  <tr>
                    <td style="width: 250px ">客人/Customer {{printedHistory.ticketInformation.customer}}</td>
                  </tr>
                  <tr>
                    <td style="width: 250px ">房号/RoomNo {{printedHistory.ticketInformation.roomNo}}</td>
                  </tr>
                  <tr>
                    <td style="width: 250px ">预离/Leave {{printedHistory.ticketInformation.leave}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="other_fee" v-if="printedHistory.showState" style="border-bottom: 1px solid #DADADA !important">
            <div class="paymentVoucher">
              <h2 style="font-size: 14px;text-align: center;">付款信息/PaymentVoucher</h2>
              <table style="width: 100%;font-size:14px;">
                <tbody  style="font-weight: 400">
                <tr>
                  <td style="width: 250px ">房价/Price ￥{{printedHistory.ticketInformation.price}}</td>
                </tr>
                <tr>
                  <td style="width: 250px ">押金/Deposit ￥{{printedHistory.ticketInformation.deposit}}</td>
                </tr>
                <tr>
                  <td style="width: 250px ">总金额/TotalSum ￥{{printedHistory.ticketInformation.totalSum}}</td>
                </tr>
                <tr>
                  <td style="width: 250px ">付款方式/PayMthod {{printedHistory.ticketInformation.paymentMthod}}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="other_fee" v-else>
            <div class="paymentVoucher">
              <h2 style="font-size: 14px;text-align: center;" v-if="printedHistory.ticketInformation.paymentMthod == '现金退款' || printedHistory.ticketInformation.paymentMthod == '微信退款'">退款信息/RefundInformation</h2>
              <h2 style="font-size: 14px;text-align: center;" v-else>缴费信息/PaymentInformation</h2>
              <table style="width: 100%; border-bottom: 1px solid #DADADA;font-size:14px" v-if="printedHistory.ticketInformation.paymentMthod == '现金退款' || printedHistory.ticketInformation.paymentMthod == '微信退款'">
                <tbody style="font-weight: 400">
                  <tr>
                    <td style="width: 250px ">总金额/TotalSum ￥{{printedHistory.ticketInformation.totalSum}}</td>
                  </tr>
                  <tr>
                    <td style="width: 250px ">支付方式/PayMthod  {{printedHistory.ticketInformation.paymentMthod}}</td>
                  </tr>
                </tbody>
              </table>
              <table style="width: 100%;border-bottom: 1px solid #DADADA;font-size:14px" v-else>
                <tbody  style="font-weight: 400">
                  <tr>
                    <td style="width: 250px ">应收/Receivables ￥{{printedHistory.ticketInformation.totalSum}}</td>
                  </tr>
                  <tr>
                    <td style="width: 250px ">实收/ActualReceipts ￥{{printedHistory.ticketInformation.moneyInput}}</td>
                  </tr>
                  <tr>
                    <td style="width: 250px ">找零/GiveChange ￥{{printedHistory.ticketInformation.moneyInput - printedHistory.ticketInformation.totalSum}}</td>
                  </tr>
                  <tr>
                    <td style="width: 250px ">支付方式/PayMthod  {{printedHistory.ticketInformation.paymentMthod}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div>
            <div class="guestInstructions" v-if="printedHistory.showState">
              <h2 style="font-size: 14px;text-align: center;">宾客须知/GuestInstructions</h2>
            </div>
            <div style="font-size:14px;font-weight: 400" v-if="printedHistory.showState">
              1. 每日最后退房时间为中午{{printedHistory.ticketInformation.setUpCheckOutTime}},如超过{{printedHistory.ticketInformation.setUpCheckOutTime}}时，须加收超钟房费。
            </div>
            <div style="font-size:14px;padding-top:5px;font-weight: 400" v-if="printedHistory.showState">
              2.服务电话 {{printedHistory.ticketInformation.telphone}}
            </div>
            <div style="text-align:center;font-weight: 400" >
              <h2>欢迎下次光临</h2>
              <div id="wxgzhImgPath" style="text-align:center;">
                  <img width="150px" height="150px" src="../../assets/hotel-erweima.jpg"/>
              </div>
            </div>
            <div>
              <h2 style="font-size: 14px;text-align: center;">扫码关注微信公众号支持在线定房，让您行程无忧。WeChat scan QR code，Support online booking Let your journey be free from worry。</h2>
            </div>
          </div>
        </div>
      </div>
      <el-row>
        <el-col :span="24" style="text-align: center">
          <el-button type="primary" size="mini" v-print="'#print'">打印</el-button>
        </el-col>
      </el-row>
    </el-dialog>
      <!--    打印票据弹窗 结束-->
  </div>
</template>
<script>
import { dateFtt } from "../../assets/utils";
import $ from '../../assets/jquery'
import { ZK,getBrowserType,issOnlineUrl,browserFlag,setting,setCertificateData,msgType,openMessage,closeMessage,strlen,messageBox,Device,createISSonlineDevic } from "../../assets/baseISSOnline"
import { mapState } from 'vuex'
import  * as face from "../../assets/interface";
import roomDetail from './components/roomDetail'
import moment from 'moment';
var obj = face.embed_reader.getOBJ(face.READER_TYPE._reader_type_contactLess);
var icdev = -1;
export default {
  components: {
    'room-detail': roomDetail
  },
  name: 'roomState',
  data(){
    let setNumber = (rule, value, callback) => {
      if (Number(value) > this.reservation.allEmptyRoom) {        
         callback(new Error('保留数量不能大于总套数'))
      }
      else{
        callback()
      }
    }
    return{
      queryTime:{   //查询时间内所需参数
        checkInDate:'',
        checkOutDate:'',
        hsId:0,
      }, 
      repairbutton:'',
      singledata:0, //计算第一次房态数据
      testdata:[],
      roomrate:true, //应付/退房费
      money:true,//应付/退金额
      roomDetailRowData: null,
      roomDetailDialog: false,
      isDot: false,
      listLoading: false,
      interval: null,
      loading: false,
      screenState: false,
      screenLabelWidth: '75px',
      quickCheckIn: { // 快速入住
        dialogQuickCheckIn: false,
        value: '',
        keepReservationList: [], // 保留 / 预定订单列表
        chooseRoomType: '',
        vacantRoomList: {}, // 空房的列表
        selectedRoomType: '', // 选中的房间类型
        selectedRoomKeep: '', // 选中的 预定 / 保留 房间
        currentRoomData: ''// 当前选中的房间
      },
      quickCheckOut: { // 快速退房
        dialogQuickCheckOut: false,
        value: '',
        canCheckOut: [], // 可退房数据
        selectedRoom: '',//选中的房间
      },
      reservation: {//保留办理
        params: {
          popName: '',
          popTelephone: '',
          popIdcard: '',
          jsrcBeginTime: '',
          jsrcEndTime: '',
          hsRoomType: '单人房',
          jsrcRemarks: '',
          number: '',
        },
        dialogReservation: false,
        checkOutLimit: {},
        allEmptyRoom: 0,
        rules: {//验证规则
          popName: [
            {required: true,message: '客户姓名不能为空',trigger: 'blur',},
            {min: 1,max: 50,message: '客户姓名长度为1-50',trigger: 'blur',},
          ],
          popTelephone: [
            {required: true,message: '手机号码不能为空',trigger: 'blur',},
            {
              pattern: /^1[3|4|5|7|8][0-9]\d{8}$/,
              message: '手机号格式错误',
              trigger: 'blur'
            }
          ],
          popIdcard: [
            {required: true,message: '证件号码不能为空',trigger: 'blur',},
            {
              pattern: /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,
              message: '证件号码格式错误',
              trigger: 'blur'
            }
          ],
          jsrcBeginTime: [
            {required: true,message: '预约时间不能为空',trigger: 'change',},
          ],
          jsrcEndTime: [
            {required: true,message: '退房时间不能为空',trigger: 'change',},
          ],
          number: [
            {required: true,message: '套数不能为空',trigger: 'blur',},
            {validator: setNumber,trigger: 'blur' },
          ],
        },
      },
      collection: {//取单
        dialogCollection: false,
        dataList: [],
        rowData: null,
        loading: false,
      },
      order: {
        dialogOrderDetails: false,
        params: {
          address: '',
          jsrcState: '',
          checked: false,
          popName: '',//客户姓名
          popTelephone: '',//手机号码
          popNameRemark: '',//备注
          popIdcard: '',//证件号码
          popIdcardType: '',//证件类型
          popBirth: '',//生日
          popIdcardAddress: '',//户籍地址
          popNation: '',//民族
          jsrrCheckInNum: '',//入住次数
          jcuType: '门店',//渠道类型
          jcuGroupType: '',//群体分类
          jcuId:'',  // 渠道id
          popImgPath: '',
          popSex: '',
          jppPlanName: '',//价格方案
          jppId: '',
          jcuAllowCredit: '',//允许挂账
          jcuMaxCredit: '',//挂账额度
          jcuMemberLevel: '',//会员级别
          jsrcBeginTime: '',//预定时间
          jsrcEndTime: '',//退房时间
          jsrcTotalDays: '',//总天数
          jsrcAmountPayable: '',//总房价
          jsrcDailyPrice: '',//日均价格
          jsrcDeposit: '',//客房押金
          jsrcOrderSource: '上门客户',//订单来源
          jsrcTotalPrice: '',//折后金额
          jsrcActualOccupancyTime: '',//入住时间
          jsrcActualDepartureTime: '',//退房时间
          jsrcPaymentMethod: '',//付款方式
          jsrcTypeOccupancy: '普通客房',//入住类型
          jsrcRemarks: '',//订单备注
          jspCredit: '',//签单人挂账额度
          jsrcFirstPay: 0,//已付金额
          jsrcArrears: 0, //欠费金额
          diagramOrderState: 1,
          popTmpPARMS: [],
          doorCardPARMS: [],
          payType: '',
          authCode: '',
          hsId: '',
          discountNum: 1,
          jsrcId: '',
          jtoId: null,
          offlinePassword: ''
          
        },
        rules: {//验证规则
          popName: [
            {required: true,message: '客户姓名不能为空',trigger: 'blur',},
          ],
          popTelephone: [
            {required: true,message: '手机号码不能为空',trigger: 'blur',},
            {
              pattern: /^1[3|4|5|7|8][0-9]\d{8}$/,
              message: '手机号格式错误',
              trigger: 'blur'
            }
          ],
          popIdcardType: [
            {required: true,message: '证件类型不能为空',trigger: 'change',},
          ],
          popIdcard: [
            {required: true,message: '证件号码不能为空',trigger: 'blur',},
            {
              pattern: /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,
              message: '证件号码格式错误',
              trigger: 'blur'
            }
          ],
          popBirth: [
            {required: true,message: '生日不能为空',trigger: 'change',},
          ],
          popIdcardAddress: [
            {required: true,message: '户籍地址不能为空',trigger: 'blur',},
          ],
          popNation: [
            {required: true,message: '民族不能为空',trigger: 'blur',},
          ],
          jsrcEndTime: [
            {required: true,message: '退房时间不能为空',trigger: 'change',},
          ],
          jsrcTypeOccupancy: [
            {required: true,message: '入住类型不能为空',trigger: 'change',},
          ],
          jsrcOrderSource: [
            {required: true,message: '订单来源不能为空',trigger: 'change',},
          ],
        },
        reserve: {
          popName: '',//预订人
          popTelephone: '',//预订电话
          jsrcBookingWay: '',//预订途径
        },
        payable: 0,//应付金额
        deviceInfo: [
          {
            deviceType: '空调',
            url: require('../../assets/ic_dt_aircondition_icon_gray.png'),
            html: '<p>该房间尚未绑定相关设备</p>',
          },{
            deviceType: '门锁',
            url: require('../../assets/doorLock_gray.png'),
            html: '<p>该房间尚未绑定相关设备</p>',
          },{
            deviceType: '插卡面板',
            url: require('../../assets/baka.png'),
            html: '<p>该房间尚未绑定相关设备</p>',
          },{
            deviceType: '窗帘',
            url: require('../../assets/ic_dt_curtain_icon_gray.png'),
            html: '<p>该房间尚未绑定相关设备</p>',
          },{
            deviceType: 'SOS报警器',
            url: require('../../assets/ic_dt_safe_sos_icon_gray.png'),
            html: '<p>该房间尚未绑定相关设备</p>',
          },{
            deviceType: '电子门牌',
            url: require('../../assets/ic_dt_safe_doorbell_icon_gray.png'),
            html: '<p>该房间尚未绑定相关设备</p>',
          },
        ],
        dataEquipment: [],
        dataFollowUp: [],//跟进
        dataRemind: [],//提醒
        dataOrder: [],//订单信息
        checkInBtn: false,//入住按钮
        addPeople: false,//入住按钮
        readCardFlag: false,//读取身份证
        peopleInformation: {},
        dataVacantRoom: [],
        orderChannel: [],//群体分类下拉菜单
        retainCheckOutLimit: {},//保留退房日期限制
        discountApplication: true
      },
      chanceTime: {
        params: {
          hsRoomType: '',
          jppId: '',
          jsrcBeginTime: '',
          jsrcEndTime: '',
          jsrcTypeOccupancy: '',
        },
        checkOutLimit: {},
        start: '',
        end: '',
        dialogChanceTime: false,
        rowData: {},
        rules: {//验证规则
          start: [
            {required: true,message: '入住时间不能为空',trigger: 'change',},
          ],
          end: [
            {required: true,message: '退房时间不能为空',trigger: 'change',},
          ],
        },
      },
      retain: {
        shortRentContractPARM: {
          hsId: '',
          jsrcTotalDays: 0,
          jsrcDailyPrice: 0,
          jsrcTotalPrice: 0,
          jsrcAmountPayable: 0,
          jsrcDeposit: 0.01,
          jsrcBeginTime: '',
          jsrcEndTime: '',
          jsrcPeople: '',
          popName: '',
          jsrcState: '保留',
          popTelephone: '',
          popIdcard: '',
          jsrcRemarks: '',
        },
        dialogRetainOrder: false,
        rules: {//验证规则
          popName: [
            {required: true,message: '客户姓名不能为空',trigger: 'blur',},
            {min: 1,max: 50,message: '客户姓名长度为1-50',trigger: 'blur',},
          ],
          popTelephone: [
            {required: true,message: '手机号码不能为空',trigger: 'blur',},
            {min: 1,max: 50,message: '手机号码长度为1-50',trigger: 'blur',},
          ],
        },
      },
      extend: {//续住
        params: {
          newEndTime: '',//新退房时间
          newTotalDay: '',//总共天数
          dailyPrice: '',//日均价格
          payableMoney: '',//应付金额
          payType: '',
          authCode: '',
          arrears: ''
        },
        checkOutTime: '',//退房时间
        dialogExtend: false,
        extendLimit: {},//日期限制
        rules: {//验证规则
          newEndTime: [
            {required: true,message: '新退房时间不能为空',trigger: 'change',},
          ],
        },
      },
      checkOut: {//退房
        params: {
          amountOfArrears: 0.0,//欠费金额
          additionalSum: 0.0,//附加金额
          checkOutDeposit: 0.0,//客房押金
          refundedRentMoney: 0.0,//应退房费
          absrefundedRentMoney: 0.0,//应付房费
          refundedMoney: 0.0,//应退金额
          absrefundedMoney: 0.0,//应退金额
          amountReceived: 0.0,//实收金额
          payType: '',
          usersId: '',//用户
          additionalDescription: '',//附属信息（附加消费具体数据）
          authCode: '',
          jppId: '',
          password: '',
        },
        dialogCheckOutRoom: false,
        popoverCheckOut: false,
        cleaning: '',
        consumption: '',//附加消费
        unitPrice: '',//附加单价
        consumptionAmount: 0,//消费金额
        dataTable: [],
        rules: {//验证规则
          cleaning: [
            {required: true,message: '用户不能为空',trigger: 'change',},
          ],
          password: [
            {required: true,message: '密码不能为空',trigger: 'blur',},
          ],
        },
      },
      chanceRoom: {//换房
        params: {
          reason: '',//换房原因
          hsId: '',//可换客房
          user: '',//保洁人员
          cleanRepHopeTime: '',//保洁时间
          changeHouseMoney: 0,//补房差价
          payType: '',
          authCode: '',
        },
        roomType: '',
        dialogOrderChanceRoom: false,
        rules: {//验证规则
          hsId: [
            {required: true,message: '可换客房不能为空',trigger: 'change',},
          ],
          user: [
            {required: true,message: '保洁人员不能为空',trigger: 'change',},
          ],
          cleanRepHopeTime: [
            {required: true,message: '保洁时间不能为空',trigger: 'change',},
          ],
        },
      },
      followUp: {//写跟进
        params: {
          followUpType: '手动跟进',//类型
          reminderTime: '',//提醒时间
          record: '',//操作
        },
        title: '操作',
        dialogOrderFollowUp: false,
        rules: {//验证规则
          reminderTime: [
            {required: true,message: '提醒时间不能为空',trigger: 'change',},
          ],
          record: [
            {required: true,message: '操作或内容不能为空',trigger: 'blur',},
          ],
        },
      },
      receivables: {//收银
        dialogReceivables: false,
        state: '',
        title: '',
        dialog: '',
        receivable: '',//应收
        paymentCode: '',//付款码
        netReceipts: '',//实收
        giveChange: '',//找零
        rules: {//验证规则
          paymentCode: [
            {required: true,message: '付款码不能为空',trigger: 'blur',},
            {len: 18,message: '付款码格式错误',trigger: 'blur',},
            // {min: 1,max: 50,message: '地址不能为空长度为 1 到 50 个字符',trigger: 'blur',}
          ],
          netReceipts: [
            {required: true,message: '实收不能为空',trigger: 'blur',},
          ],
        },
        dialogAlert: false,
        loading: false
      },
      discount: {//折扣申请
        temporaryOrderPARM: {
          jtoUserId: '',//授权人ID
          jtoRemark: '',//折扣原因
          password: '',//授权密码
          jtoDiscount: '',//折扣率

          jtoHsId: '',//对应挂单id（未租ID）
          jtoRoomType: '',//房型（普通客房、钟点客房）
          jtoTotalHousingPrice: '',//订单总价
          jtoDayPrice: '',//日均房价
          jtoDiscountPrice: '',//折扣后总价
          jtoAveragePrice: '',//折扣后均价
          jtoStatus: '处理中',//授权状态（处理中）
          jtoShortInfo: '',
          cleaning: '',
        },
        grantMode: '现场授权',
        dialogDiscount: false,
        rules: {//验证规则
          jtoDiscount: [
            {required: true,message: '折扣比例不能为空',trigger: 'blur',},
            {min: 0,max: 1,message: '折扣比例为1-10',trigger: 'blur',},
          ],
          cleaning: [
            {required: true,message: '授权人不能为空',trigger: 'change',},
          ],
          jtoRemark: [
            {required: true,message: '折扣原因不能为空',trigger: 'blur',},
            {min: 1,max: 50,message: '折扣原因长度为1-50',trigger: 'blur',},
          ],
          password: [
            {required: true,message: '授权密码不能为空',trigger: 'blur',},
            {min: 1,max: 50,message: '授权密码长度为1-50',trigger: 'blur',},
          ],
        },
      },
      doorCard: {//门卡授权
        checkDevice: {},
        doorCardPARM: {
          authorizeType: 0,
          jourDeviceId: '',
          deviceIds: null,
          houseAddress: '',
          jdcCardId: '',
          jdcCardNum: '',
          jdcDeadlineTime: '',
          jdcHsId: null,
          jdcMaxUnlockingTimes: null,
          jdcUserId: null,
          popId: null,
          popIdcard: '',
          popName: '',
          popIndex: 0,
          startTime: '',
          encryptMode: '',
          sendMessage: ''
        },
        dialogDoorCard: false,
        checkList: [],
        doorLocks: '',
        radio1:'1',
        loading: false,
        rules: {//验证规则
          jdcCardId: [
            {required: true,message: '授权不能为空',trigger: 'blur',},
            {min: 1,max: 50,message: '授权长度为1-50',trigger: 'blur',},
          ],
          jdcDeadlineTime: [
            {required: true,message: '期限不能为空',trigger: 'change',},
          ],
        },
      },
      data: {//数据分页查询
        params: {
          hsAddCommunity: '',//门店名称
          hsRoomType: '',//客房类型
          hsAddDoorplateno: '',//房号
          hsAddFloor: '',//楼层
          screenType: '所有客房',//筛选按钮
        },
        sortBtnNum: {
          allNum: 0,
          vacantCleanNum: 0,
          vacantCleaningNum: 0,
          vacantRepairNum: 0,
          todayArriveNum: 0,
          todayLeaveNum: 0,
          todayReserveNum: 0,
          arrearsNum: 0,
          reserveNum: 0,
          retainNum: 0,
          ordinaryNum: 0,
          hourNum: 0,
        },
        tabs: '全部',
      },
      dataList: [],
      dataAllCustomer: [],
      dataVacantRoo: [],
      floorList: [],
      rowData: {},
      listHeight: `${window.innerHeight - 215}px`,
      dateLimit: {//日期限制
        disabledDate(time) { 
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      printedHistory: {
        dialogprintedHistory: false,
        showState: true,
        ticketInformation: {}, // 票据信息
      },
      messagePush: {
        dialogMessagePush: false,
        params: {
          limit: 5,//每页的条数
          cursor: 1,//初始页
        },
        dataList: [],
        total: 0,         //总条数
        pages: 0,      //总页数
      },
      bookingOrder: {
        dialogBookingOrder: false,
        dataList: [],
        params: {
          limit: 10,//每页的条数
          cursor: 1,//初始页
        },
        total: 0,         //总条数
        pages: 0,      //总页数
      },
      popIdcardJson: {},
      beginTimeOptions: {
        start: '00:00',
        step: '01:00',
        end: '23:00'
      },
      endTImeOptions:{
        start: '00:00',
        step: '01:00',
        end: '23:00'
      },
      timer:0 //保留办理定时器
    }
  },
  watch: {
    'checkOut.params.refundedMoney':function(n,o){
      this.checkOut.params.absrefundedMoney=n
      if(n<0){
        this.roomrate=false
        this.checkOut.params.absrefundedRentMoney=Math.abs(this.checkOut.params.refundedRentMoney)
      }else{
        this.roomrate= true
      }
    },
    'checkOut.params.refundedMoney':function(n,o){
      if(n<0){
        this.money=false
        this.checkOut.params.absrefundedMoney=Math.abs(this.checkOut.params.refundedMoney)
      }else{
        this.money=true
      }
    },
    'order.params.popIdcard':function(n,o){
        let a=n.substr(6,4)
        let b=n.substr(10,2)
        let c=n.substr(12,2)
        this.order.params.popBirth=`${a}-${b}-${c}`
    },
    // 'chanceTime.start':function(n,o){
    //   // console.log(n)
    //   this.queryTime.checkInDate=n
    //   this.inquireRoom()
    // },
    // 'chanceTime.end':function(n,o){
    //   this.queryTime.checkOutDate=n
    //   // alert(typeof(this.order.params.hsId))
    //   console.log(this.order.params.hsId)
    //   this.inquireRoom()
    // },
    //一晚
  },
  computed: {
    ...mapState(['setupList','userNameList','userName','authe'])
  },
  created(){
    // console.log(this.$JsMath.accAdd(6.1,0.7))
    this.getDataList()
    this.getDoorCardState()
    this.getWeixinOrder()
    // this.getAllCustomer()
  },
  deactivated() {
    console.log('deactivated')
    document.onkeydown = null
  },
  mounted(){
    window.onresize = () => {
      return (() => {
        this.listHeight = `${window.innerHeight - 215}px`
      })()
    }
    // this.$nextTick(() => {
    //   this.interval = setInterval(() => {
    //     this.getDoorCardState()
    //     this.getWeixinOrder()
    //     this.getMessagePush()
    //   }, 10000)
    // })
    this.cardlistdemo()
  },
  updated() {
    // console.log(this.testdata)
  },
  activated() {
    console.log('请求数据')
    console.log('activated')
    document.onkeydown = (e) => {
      let key = e.keyCode
      console.log(key)
      if (key == 120 && this.receivables.dialogReceivables) {
        this.receivables.dialogAlert = false
        this.receivables.dialogReceivables = false
        console.log('开启扫码收钱')
        this.checkInReceivables(2, 'checkin')
      }
    }
    this.$nextTick(() => {
      this.interval = setInterval(() => {
        console.log('请求数据')
        this.getDoorCardState()
        this.getWeixinOrder()
        this.getMessagePush()
      }, 10000)
    })
    console.log(this.interval)
  },
  deactivated() {
    console.log('deactivated')
    document.onkeydown = null
    clearInterval(this.interval)
    console.log('不活跃')
  },
  destroyed(){
    // 
    clearInterval(this.interval)
  },

  methods: {
    // 查询所在时间内房间是否有预订或保留
    inquireRoom(){
      this.queryTime.checkInDate=this.chanceTime.start
      this.queryTime.checkOutDate=this.chanceTime.end
      let data=this.queryTime
      data.hsId=parseInt(this.queryTime.hsId)
      if(data.checkOutDate==null || data.checkInDate==null){
        return
      }else{
        this.$axios({
          method: 'get',
          url: `/hotel/shortRentContract/isLeisure/${data.hsId}`,
          params:{
            checkInDate:data.checkInDate,
            checkOutDate:data.checkOutDate
          }
        }).then(res => {
          console.log(res)
          if(res.data.result.length>0){
            this.$message({
              message:`${res.data.result[0].jsrcBeginTime}至${res.data.result[0].jsrcEndTime} 存在预订或保留订单`,
              type: 'warning'
            });
          }
        }).catch(err => {
          this.isError(err,this)
          // console.log(err)
        })       
      }  
    },
    inquireRoom2(){
      let data={}
      data.hsId=this.queryTime.hsId
      data.checkInDate=this.order.params.jsrcBeginTime
      data.checkOutDate=this.order.params.jsrcEndTime
      console.log(data)
      if(data.checkInDate==null || data.checkOutDate==null){
        return
      }
      this.$axios({
        method: 'get',
        url: `/hotel/shortRentContract/isLeisure/${data.hsId}`,
        params:data
      }).then(res => {
        console.log(res)
        if(res.data.result.length>0){
          this.$message({
            message:`${res.data.result[0].jsrcBeginTime}至${res.data.result[0].jsrcEndTime} 存在预订或保留订单`,
            type: 'warning'
          });
        }
      }).catch(err => {
        this.isError(err,this)
        // console.log(err)
      })       
      
    },

    cardlistdemo(){
      let dom= document.getElementById("cardlistdemo");
      let divWidth =dom.offsetWidth;
      let divHeight = dom.offsetHeight;
      let a =Math.ceil(divWidth/120)*Math.ceil(divHeight/120)
      this.singledata=a
    },
    handleScroll(event){
      if(this.singledata>this.dataList.length){
        return
      }
      const scrollDistance =
        // 正文全文高
        event.target.scrollHeight -
        // 被卷去的高
        event.target.scrollTop -
        // 可见区域的宽度
        event.target.clientHeight;
      // 滚动条距离底部小于等于0证明已经到底了
      if (scrollDistance <= 1 ) {  
        if(this.testdata.length==this.dataList.length){
          return
        }
        let a =this.dataList.slice(this.singledata)
        this.testdata=this.testdata.concat(a)
      }      
    },
  //查询数据
    getDataList(){//查询数据
      console.log(this.data.params.screenType)
      this.listLoading = true
      this.$axios({
        method: 'get',
        url: '/hotel/house4store/getHouseDiagram',
        params: this.data.params
      }).then(res => {
        if (res.data.result.length > 0) {
          this.dataList = res.data.result
          this.testdata = res.data.result.slice(1,this.singledata+1)
          for (let i of this.dataList) {
            i.roomColor = '#EBEEF5'
            if (i.diagramState == '空') {
              i.cardColor = '#1991f0'
              i.buttonColor = 'primary'
            } else if (i.diagramState == '住') {
              i.cardColor = '#24a854'
              i.buttonColor = 'success'
            } else if (i.diagramState == '欠') {
              i.cardColor = '#24a854'
              i.buttonColor = 'danger'
            } else if (i.diagramState == '预' || i.diagramState == '留') {
              i.cardColor = '#24a854'
              i.buttonColor = 'warning'
            } else if (i.diagramState == '脏' || i.diagramState == '维' || i.diagramState == '修') {
              i.cardColor = '#6d6d7f'
              i.buttonColor = 'info'
            }
          }
          this.data.sortBtnNum = {
            allNum: this.dataList[0].totalNum,
            vacantCleanNum: this.dataList[0].vacantCleanNum,
            vacantCleaningNum: this.dataList[0].vacantCleaningNum,
            vacantRepairNum: this.dataList[0].vacantRepairNum,
            todayArriveNum: this.dataList[0].todayArriveNum,
            todayLeaveNum: this.dataList[0].todayLeaveNum,
            todayReserveNum: this.dataList[0].todayReserveNum,
            arrearsNum: this.dataList[0].arrearsNum,
            reserveNum: this.dataList[0].reserveNum,
            retainNum: this.dataList[0].retainNum,
            ordinaryNum: this.dataList[0].ordinaryNum,
            hourNum: this.dataList[0].hourNum,
          }
        } else {
          this.dataList = []
          this.data.sortBtnNum = {
            allNum: 0,
            vacantCleanNum: 0,
            vacantCleaningNum: 0,
            vacantRepairNum: 0,
            todayArriveNum: 0,
            todayLeaveNum: 0,
            todayReserveNum: 0,
            arrearsNum: 0,
            reserveNum: 0,
            retainNum: 0,
            ordinaryNum: 0,
            hourNum: 0,
          }
        }
        let arr = []
        for (let key in this.dataList) {
          if (key > 0) {
            this.dataList[key].hsId = this.dataList[key].diagramHsId
            arr.push(this.dataList[key])
          }
        }
        console.log(this.data.params.screenType)
        this.dataList = arr
        this.floorList = res.data.result[0].floorList
        this.listLoading = false
      }).catch(err => {
        this.dataList = []
        this.isError(err,this)
        this.listLoading = false
      })
    },
    getDoorCardState(){//门牌号变色
      this.$axios({
        method: 'get',
        url: '/hotel/house4store/getCardPanel',
      }).then(res => {
        let data=res.data.result
        console.log(this.dataList)
        if (data.length > 0) {
          for (let i of this.dataList) {
            for (let k of res.data.result) {
              if (i.diagramHsId == k.hsId) {
                if ( k.devState == '插卡') {
                  i.roomColor = '#303133'
                } else {
                  i.roomColor = '#EBEEF5'
                }
              }
            }
          }
        }
      }).catch(err => {
        this.isError(err,this)
        // console.log(err)
      })
    },
    getWeixinOrder(){//微信新订单提示
      this.$axios({
        method: 'get',
        url: '/hotel/shortRentContract/getWxNewOrder',
      }).then(res => {
        if (res.data.result.records.length > 0) {
          for(let i of res.data.result.records){
            let a = i.hsAddCommunity ? `${i.hsAddCommunity}\u3000` : ''
            let b = i.hsRoomType ? `${i.hsRoomType}\u3000` : ''
            let c = i.hsAddDoorplateno ? `${i.hsAddDoorplateno}` : ''
            i.address = a + b + c
            let obj = {
              diagramJsrcId: i.jsrcId,
              diagramState: '预'
            }
            this.$notify({
              title: '您有新的订单！！！',
              duration: 5000,
              dangerouslyUseHTMLString: true,
              message: `<p>${i.address}</p><br>
                        <button
                        class="el-button el-button--primary el-button--mini">
                        点击查看详情
                        </button>`,
              onClick: () => {this.orderDetails(obj)},
              position: 'bottom-right',
            });
          }
        }
      }).catch(err => {
        this.isError(err,this)
      })
    },
    getMessagePush(){
      this.$axios({
        method: 'get',
        url: '/hotel/roomMessagePush/getMessagePush',
      }).then(res => {
        if (res.data.result.length > 0) {
          for(let i of res.data.result){
            this.$notify({
              title: '消息推送！',
              duration: 5000,
              dangerouslyUseHTMLString: true,
              message: `<p>${i.jrmpMessage}</p>`,
              position: 'bottom-right',
            });
          }
          this.isdot = true
        } else {
          this.isdot = false
        }
      }).catch(err => {
        this.isError(err,this)
      })
    },
    getAllCustomer(){//获取所有客户
      this.$axios({
        method: 'get',
        url: '/hotel/shortRentRenter/all',
      }).then(res => {
        if (res.data.result && res.data.result.length > 0) {
          this.dataAllCustomer = res.data.result
        }
      }).catch(err => {
        this.isError(err,this)
      })
    },
    tabPaneChange(floor){//点击楼层
      this.data.params.hsAddFloor = this.data.tabs == '全部' ? '' : this.data.tabs
      this.getDataList()
    },
  //快速入住
    getQuickCheckIn () { // 查询保留 / 预定订单
      this.quickCheckIn.chooseRoomType = ''
      this.quickCheckIn.selectedRoomType = ''
      this.$axios({
        url: '/hotel/shortRentContract/getQuickCheckIn',
        method: 'get'
      }).then( res => {
        this.quickCheckIn.keepReservationList = res.data.result
      }).catch(err => {
        this.isError(err,this)
      })
    },
    getVacantRoom () { // 查询空房
      this.quickCheckIn.selectedRoomKeep = ''
      this.quickCheckIn.selectedRoomType = ''
      let startTime = new Date()
      let endTime = new Date(startTime.getTime() + 24*60*60*1000)
      this.$axios({
        url: '/hotel/house4store/vacantRoom',
        method: 'get',
        params: {
          roomType: this.quickCheckIn.chooseRoomType,
          startTime: this.DateFormat(startTime, `YYYY-MM-DD ${this.setupList.jsrsuCheckInTime}:00`),
          endTime: this.DateFormat(endTime, `YYYY-MM-DD ${this.setupList.jsrsuCheckOutTime}:00`)
        }
      }).then( res => {
        this.quickCheckIn.vacantRoomList = res.data.result
      }).catch(err => {
        this.isError(err,this)
      })
    },
    getSingleRoom () {// 单条查询房间
      this.$axios({
        url: `/hotel/house4store/${this.quickCheckIn.selectedRoomType}`,
        method: 'get'
      }).then( res => {
        console.log( res.data.result)
        this.quickCheckIn.currentRoomData = res.data.result
      }).catch(err => {
        this.isError(err,this)
      })
    },
    doQuickCheckIn () {
      console.log(this.quickCheckIn.selectedRoomKeep)
      if(this.quickCheckIn.selectedRoomKeep != '') {
        let data = this.quickCheckIn.selectedRoomKeep.split('-')
        if (data[1] == '预定') {
          this.order.params.diagramState = '预'
        } else if(data[1] == '保留') {
          this.order.params.diagramState = '留'
        }
        this.chanceTime.rowData.diagramRoomType = data[2]
        this.$axios({
          method: 'get',
          url: `/hotel/shortRentContract/${data[0]}`,
        }).then(res => {
          if (res.data.result) {
            let a = res.data.result.hsAddCommunity ? `${res.data.result.hsAddCommunity}\u3000` : ''
            let b = res.data.result.hsRoomType ? `${res.data.result.hsRoomType}\u3000` : ''
            let c = res.data.result.hsAddDoorplateno ? `${res.data.result.hsAddDoorplateno}` : ''
            this.order.params.address = a + b + c
            this.order.params.jsrcState = res.data.result.jsrcState
            for (let i in res.data.result) {
              for (let k in this.order.params) {
                if (i == k) {
                  this.order.params[k] = res.data.result[i]
                }
              }
            }
            this.order.params.popName = ''
            this.order.payable = this.order.params.jsrcTotalPrice
            this.order.dataOrder = res.data.result
            if (res.data.result.jsrcState == '已住') {
              this.alreadyLivedOrder(res.data.result)
            } else if (res.data.result.jsrcState == '保留') {
              this.retainOrder(res.data.result)
            } else if (res.data.result.jsrcState == '预定') {
              this.reserveOrder(res.data.result)
            }
            if (res.data.result.followUp && res.data.result.followUp.length > 0) {//跟进
              this.order.dataFollowUp = res.data.result.followUp
            } else {
              this.order.dataFollowUp = []
            }
            if (res.data.result.customerRemind && res.data.result.customerRemind.length > 0) {//提醒
              this.order.dataRemind = res.data.result.customerRemind
            } else {
              this.order.dataRemind = []
            }
            this.order.params.diagramOrderState = res.data.result.jsrcOrderState
          }
          if (res.data.result.jsrcState != '已住') {
            this.order.params.popTmpPARMS = []
          } else {
            this.$nextTick(() => {
              this.$refs.fellowResidents.setCurrentRow(this.order.params.popTmpPARMS[0])
            })
          }
          this.order.dialogOrderDetails = true
          this.quickCheckIn.dialogQuickCheckIn = false
        }).catch(err => {
          this.isError(err,this)
        })
      } else {
        // if (this.quickCheckIn.chooseRoomType == '') {
        //   this.$message({
        //     showClose: true,
        //     duration: 6000,
        //     message: `请先选择一个房型`,
        //     type: 'error'
        //   })
        //   return
        // }
        if (this.quickCheckIn.selectedRoomType == '') {
            this.quickCheckIn.currentRoomData = this.quickCheckIn.vacantRoomList[0]
        }
        let startTime = new Date()
        let endTime = new Date(startTime.getTime() + 24*60*60*1000)
        startTime = this.DateFormat(startTime, `YYYY-MM-DD ${this.setupList.jsrsuCheckInTime}:00`)
        endTime = this.DateFormat(endTime, `YYYY-MM-DD ${this.setupList.jsrsuCheckOutTime}:00`)
        this.chanceTime.start = startTime
        this.chanceTime.end = endTime
        this.order.params.diagramState = '空'
        this.chanceTime.rowData = this.quickCheckIn.currentRoomData
        this.order.params.address = this.quickCheckIn.currentRoomData.hsAddCommunity + ' ' + this.quickCheckIn.currentRoomData.hsRoomType + ' ' + this.quickCheckIn.currentRoomData.hsAddDoorplateno
        this.order.params.jsrcState = '空房'
        this.order.params.jcuType = '门店'
        this.chanceTime.rowData.diagramRoomType = this.quickCheckIn.currentRoomData.hsRoomType

        this.getDefaultChannel()
        this.order.checkInBtn = true
        this.order.params.hsId = this.quickCheckIn.currentRoomData.hsId
        this.order.params.jsrcBeginTime = this.chanceTime.start
        this.order.params.jsrcEndTime = this.chanceTime.end
        this.order.payable = this.order.params.jsrcTotalPrice
        this.order.dialogOrderDetails = true
        this.quickCheckIn.dialogQuickCheckIn = false
      }
    },
  //快速退房
    getQuickCheckOut() { // 查询可退房数据
      this.$axios({
        url: '/hotel/shortRentContract/getQuickCheckOut',
        method: 'get'
      }).then( res => {
        this.quickCheckOut.canCheckOut = res.data.result
      }).catch(err => {
        this.isError(err,this)
      })
    },
    QuickCheckOut() {//退房办理
      if(this.quickCheckOut.selectedRoom != '') {
        let data = this.quickCheckOut.selectedRoom.split('-')
          if(data[1] == '已住') {
            this.order.params.diagramState = '住'
          }
        this.$axios({
          method: 'get',
          url: `/hotel/shortRentContract/${data[0]}`,
        }).then(res => {
          if (res.data.result) {
            let a = res.data.result.hsAddCommunity ? `${res.data.result.hsAddCommunity}\u3000` : ''
            let b = res.data.result.hsRoomType ? `${res.data.result.hsRoomType}\u3000` : ''
            let c = res.data.result.hsAddDoorplateno ? `${res.data.result.hsAddDoorplateno}` : ''
            this.order.params.address = a + b + c
            this.order.params.jsrcState = res.data.result.jsrcState
            for (let i in res.data.result) {
              for (let k in this.order.params) {
                if (i == k) {
                  this.order.params[k] = res.data.result[i]
                }
              }
            }
            this.order.params.popName = ''
            this.order.payable = res.data.result.jsrcTotalPrice
            this.order.dataOrder = res.data.result
            if (res.data.result.jsrcState == '已住') {
              this.alreadyLivedOrder(res.data.result)
            } else if (res.data.result.jsrcState == '保留') {
              this.retainOrder(res.data.result)
            } else if (res.data.result.jsrcState == '预定') {
              this.reserveOrder(res.data.result)
            }
            if (res.data.result.followUp && res.data.result.followUp.length > 0) {//跟进
              this.order.dataFollowUp = res.data.result.followUp
            } else {
              this.order.dataFollowUp = []
            }
            if (res.data.result.customerRemind && res.data.result.customerRemind.length > 0) {//提醒
              this.order.dataRemind = res.data.result.customerRemind
            } else {
              this.order.dataRemind = []
            }
            this.order.params.diagramOrderState = res.data.result.jsrcOrderState
          }
          if (res.data.result.jsrcState != '已住') {
            this.order.params.popTmpPARMS = []
          } else {
            this.$nextTick(() => {
              this.$refs.fellowResidents.setCurrentRow(this.order.params.popTmpPARMS[0])
            })
          }
          this.order.dialogOrderDetails = true
          this.quickCheckOut.dialogQuickCheckOut = false
          this.checkOutRoom()
          console.log(this.order.dataOrder)
        }).catch(err => {
          this.isError(err,this)
        })
      }else {
        this.$message({
          showClose: true,
          duration: 6000,
          message: `请选择一个房间`,
          type: 'error'
        })
      }
    },
    CloseQuickCheckOut(){//快速退房窗口关闭
      this.quickCheckOut.selectedRoom = ''
    },
  //批量保留办理
    getReservation(){
      
      this.reservation.dialogReservation = true
    },
    reservationStartChange(){
      console.log(this.reservation.params.jsrcBeginTime,this.reservation.params.jsrcBeginTime == null)
      if(this.reservation.params.jsrcBeginTime == null) {
        this.reservation.params.jsrcEndTime = null
        return 
      }
      console.log(233)
      let oldCheckOutTime = new Date((this.reservation.params.jsrcBeginTime).substr(0,10)).getTime()
      this.reservation.checkOutLimit = {//日期限制
        disabledDate(time) {
          return time.getTime() < new Date(oldCheckOutTime).getTime();
        }
      }
      if (this.reservation.params.jsrcBeginTime && this.reservation.params.jsrcEndTime) {
        this.$axios({
          method: 'get',
          url: `/hotel/house4store/vacantRoom`,
          params: {
            roomType: this.reservation.params.hsRoomType,
            startTime: this.reservation.params.jsrcBeginTime,
            endTime: this.reservation.params.jsrcEndTime,
          }
        }).then(res => {
          this.reservation.allEmptyRoom = res.data.result.length
        }).catch(err => {
          this.isError(err,this)
        })
      }
    },

    reservationSave(formName){
      let now = new Date().valueOf();
      //第一次点击
      if(this.timer == 0){
        this.$refs[formName].validate((valid) => {
          console.log(valid)
          if (valid) {
            this.$axios({
              method: 'post',
              url: `/hotel/shortRentContract/batchRetain`,
              params: this.reservation.params
            }).then(res => {
              this.$message.success('保留成功')
              this.getDataList()
              this.reservation.dialogReservation = false
            }).catch(err => {
              this.isError(err,this)
            })            
          } else {
            console.log("失败");
            return false;
          }
        });
        this.timer = now;
      }else{
        if((now-this.timer) > 2000){
            //重置上一次点击时间，2000是我自己设置的2秒间隔，根据自己的需要更改
          this.timer = now;             
          this.$refs[formName].validate((valid) => {
            console.log(valid)
            if (valid) {
              this.$axios({
                method: 'post',
                url: `/hotel/shortRentContract/batchRetain`,
                params: this.reservation.params
              }).then(res => {
                this.$message.success('保留成功')
                this.getDataList()
                this.reservation.dialogReservation = false
              }).catch(err => {
                this.isError(err,this)
              })            
            } else {
              console.log("失败");
              return false;
            }
          });
        }else{
            this.$message({
              message: '请勿重复提交',
              type: 'warning'
            });
        }
      }
    },
    closeDialogReservation(){
      this.resetForm(['reservation'],this)
      this.reservation.params = {
          popName: '',
          popTelephone: '',
          popIdcard: '',
          jsrcBeginTime: '',
          jsrcEndTime: '',
          hsRoomType: '单人房',
          jsrcRemarks: '',
          number: '',
        }
      this.reservation.dialogReservation = false
      this.reservation.checkOutLimit = {}
    },
    
  //取单
    getCollection(){
      this.$axios({
        method: 'get',
        url: `/hotel/temporaryOrder`,
      }).then(res => {
        this.collection.dataList = res.data.result
        this.collection.dialogCollection = true
      }).catch(err => {
        this.isError(err,this)
      })
    },
    async orderCollection(row,state){
      if (state == '取单') {
        for (let i of this.dataList) {
          if (i.diagramHsId == row.jtoHsId) {
            this.order.params.jtoId = row.jtoId
            this.order.params.diagramState = '空'
            this.order.params.jsrcState = '空房'
            this.order.params.address = row.jtoAddress
            this.order.params.hsId = row.jtoHsId
            this.chanceTime.rowData.diagramRoomType = row.hsRoomType
            let contract = JSON.parse(row.jtoShortInfo)
            await this.jcuGroupTypeChange(contract.jcuId)
            for(let i in contract){
              for(let j in this.order.params){
                if(i == j || j == "jcuGroupType" && i == "jcuId"){
                  this.order.params[j] = contract[i]
                }
              }
            }
            this.order.checkInBtn = true
            this.order.dialogOrderDetails = true
            this.order.discountApplication = false
            this.collection.dialogCollection = false
            break
          }
        }
      } else if (state == '取消挂单' || state == '取消授权') {
        this.collection.loading=true
        this.$axios({
          method: 'put',
          url: `/hotel/temporaryOrder/${row.jtoId}/cancelTemporaryOrder`
        }).then(res => {
          this.collection.loading=false
          this.$message.success('取消挂单成功')
          this.getCollection()
        }).catch(err => {
          this.collection.loading=false
          this.isError(err,this)
        })
      } else if (state == '重新请求') {
        this.collection.loading=true
        this.$axios({
          method: 'put',
          url: `/hotel/temporaryOrder/${row.jtoId}/reRequestTemporaryOrder`
        }).then(res => {
          this.collection.loading=false
          this.$message.success('重新请求折扣授权成功')
          this.getCollection()
        }).catch(err => {
          this.collection.loading=false
          this.isError(err,this)
        })
      }
    },
    closeDialogCollection(){

    },
  //订单
    orderDetails(row){//订单详情
    console.log(row)
    this.repairbutton=row.diagramState
      if (typeof this.authe.Hotel_Core_RoomStateDiagram_OrderDetails == 'undefined') {
        this.$message.warning('无权限操作')
        return
      }
      if(row.diagramState == '脏' || row.diagramState == '维' || row.diagramState == '修'){
        this.roomDetailDblclick(row)
        return;
      }
      this.chanceTime.rowData = row
      // this.rowData = row
      this.order.params.diagramState = row.diagramState
      this.listLoading = true
      this.$axios({
        method: 'get',
        url: `/hotel/shortRentContract/${row.diagramJsrcId}`,
      }).then(res => {
        this.listLoading = false
        if (res.data.result) {
          let a = res.data.result.hsAddCommunity ? `${res.data.result.hsAddCommunity}\u3000` : ''
          let b = res.data.result.hsRoomType ? `${res.data.result.hsRoomType}\u3000` : ''
          let c = res.data.result.hsAddDoorplateno ? `${res.data.result.hsAddDoorplateno}` : ''
          this.order.params.address = a + b + c
          this.order.params.jsrcState = res.data.result.jsrcState
          for (let i in res.data.result) {
            for (let k in this.order.params) {
              if (i == k) {
                this.order.params[k] = res.data.result[i]
              }
            }
          }
          console.log(res.data.result)
          console.log(this.order.params)
          this.order.params.popName = ''
          this.order.payable = this.order.params.jsrcTotalPrice
          this.chanceTime.rowData.diagramRoomType = res.data.result.hsRoomType
          this.order.dataOrder = res.data.result
          if (res.data.result.jsrcState == '已住') {
            this.alreadyLivedOrder(res.data.result)
          } else if (res.data.result.jsrcState == '保留') {
            this.retainOrder(res.data.result)
          } else if (res.data.result.jsrcState == '预定') {
            this.reserveOrder(res.data.result)
          }
          if (res.data.result.followUp && res.data.result.followUp.length > 0) {//跟进
            this.order.dataFollowUp = res.data.result.followUp
          } else {
            this.order.dataFollowUp = []
          }
          if (res.data.result.customerRemind && res.data.result.customerRemind.length > 0) {//提醒
            this.order.dataRemind = res.data.result.customerRemind
          } else {
            this.order.dataRemind = []
          }
          this.order.params.diagramOrderState = res.data.result.jsrcOrderState
        }
        if (res.data.result.jsrcState != '已住') {
          this.order.params.popTmpPARMS = []
        } else {
          this.$nextTick(() => {
            this.$refs.fellowResidents.setCurrentRow(this.order.params.popTmpPARMS[0])
          })
        }
        this.order.dialogOrderDetails = true
        console.log(this.order.dataOrder)
      }).catch(err => {
        this.listLoading = false
        this.isError(err,this)
      })
    },
    alreadyLivedOrder(result){//已住
      if (result.popTmpList && result.popTmpList.length > 0) {
        for (let i of result.popTmpList) {
          if (i.popImgPath) {
            i.popImgPath = `data:image/png;base64,${i.popImgPath}`
          }
          if (result.popId == i.popId) {
            for (let k in i) {
              for (let j in this.order.params) {
                if (k == j) {
                  this.order.params[j] = i[k]
                }
              }
            }
          }
        }
        this.order.params.popTmpPARMS = result.popTmpList
      } else {
        this.order.params.popTmpPARMS = []
      }
      for (let i of result.deviceInfo) {
        for (let k of this.order.deviceInfo) {
          if (i.deviceType == k.deviceType) {
            if (i.deviceType == '空调') {
              if (i.online == '在线') {
                k.url = require('../../assets/ic_dt_aircondition_icon.png')
              }
              k.html = `<p>设备名称：${i.deviceType}</p>
                        <p>设备状态：${i.online}</p>
                        <p>室温：${i.roomTemperature}</p>
                        <p>风速：${i.airWindSpeed}</p>
                        <p>温度：${i.temperature}</p>
                        <p>风门：${i.airSwingWind}</p>
                        <p>模式：${i.airPattern}</p>
                        <p>开关：${i.switcheState}</p>`
            } else if (i.deviceType == '门锁') {
              if (i.online == '在线') {
                k.url = require('../../assets/doorLock.png')
              }
              k.html = `<p>设备名称：${i.deviceType}</p>
                        <p>设备状态：${i.online}</p>`
            } else if (i.deviceType == '插卡面板') {
              if (i.status == '插卡') {
                k.url = require('../../assets/chaka.png')
              }
              k.html = `<p>设备名称：${i.deviceType}</p>
                        <p>设备状态：${i.online}</p>
                        <p>插卡状态：${i.status}</p>`
            } else if (i.deviceType == '窗帘') {
              if (i.status == '已开') {
                k.url = require('../../assets/ic_dt_curtain_icon.png')
              }
              k.html = `<p>设备名称：${i.deviceType}</p>
                        <p>设备状态：${i.online}</p>
                        <p>开关状态：${i.status}</p>`
            } else if (i.deviceType == 'SOS报警器') {
              if (i.status == '紧急呼叫报警') {
                k.url = require('../../assets/ic_dt_safe_sos_icon.png')
              }
              k.html = `<p>设备名称：${i.deviceType}</p>
                        <p>设备状态：${i.online}</p>
                        <p>报警状态：${i.status}</p>`
            } else if (i.deviceType == '电子门牌') {
              if (i.online == '在线') {
                k.url = require('../../assets/ic_dt_safe_doorbell_icon.png')
              }
              k.html = `<p>设备名称：${i.deviceType}</p>
                        <p>设备状态：${i.online}</p>
                        <p>门牌状态：${i.status}</p>`
            }
          }
        }
      }
    },
    retainOrder(result){//保留
      if (result.jsrcPeople) {
        let arr = JSON.parse(result.jsrcPeople)
        this.order.params.popTmpPARMS = arr
        for (let i in arr[0]) {
          for (let k in this.order.reserve) {
            if (i == k) {
              this.order.reserve[k] = arr[0][i]
            }
          }
        }
      } else {
        this.order.params.popTmpPARMS = []
      }
      this.order.reserve.jsrcBookingWay = this.order.dataOrder.jsrcBookingWay
      this.order.params.jcuType = '门店'//渠道类型
      this.order.params.jcuGroupType = ''//群体分类
      this.order.params.jppPlanName = ''//价格方案
      this.order.params.jppId = ''//价格方案
      this.order.params.jcuAllowCredit = ''//允许挂账
      this.order.params.jcuMaxCredit = ''//挂账额度
      let oldCheckOutTime = new Date(this.order.dataOrder.jsrcBeginTime).getTime()
      this.order.retainCheckOutLimit = {//日期限制
        disabledDate(time) {
          return time.getTime() < new Date(oldCheckOutTime).getTime();
        }
      }
      this.getDefaultChannel()
    },
    reserveOrder(result){//预定
      if (result.popTmpList && result.popTmpList.length > 0) {
        for (let i in result.popTmpList[0]) {
          for (let k in this.order.reserve) {
            if (i == k) {
              this.order.reserve[k] = result.popTmpList[0][i]
            }
          }
        }
        this.order.reserve.jsrcBookingWay = this.order.dataOrder.jsrcBookingWay
        this.order.params.popTmpPARMS = result.popTmpList

        this.jcuGroupTypeChange(result.popTmpList[0].jcuId)
        // this.getDefaultChannel()
      } else if (result.jsrcPeople) {
        this.retainOrder(result)
      }
    },
    popNameChange(row){//双击同住人改变信息
      this.order.params.popName = ''//客户姓名
      this.order.params.popTelephone = ''//手机号码
      this.order.params.popNameRemark = ''//备注
      this.order.params.popIdcard = ''//证件号码
      // this.order.params.popIdcardType = ''//证件类型
      this.order.params.popBirth = ''//生日
      this.order.params.popIdcardAddress = ''//户籍地址
      this.order.params.popNation = ''//民族
      this.order.params.popImgPath = ''
      console.log(row)
      console.log(this.order.params)
      for (let i in row) {
        for (let k in this.order.params) {
          if (i == k) {
            this.order.params[k] = row[i]
          }
        }
      }
    },
    checkInBtn(){//入住按钮
      this.order.checkInBtn = true
    },
    jcuTypeChange(){//渠道类型改变时
      this.order.params.jcuGroupType = ''
      this.order.params.jppPlanName = ''
      this.order.params.jppId = ''
      this.order.params.jcuAllowCredit = ''
      this.order.params.jcuMaxCredit = ''
      this.$axios({
        method: 'get',
        url: `/hotel/channelUnit/getAll`,
        params: {
          jcuType: this.order.params.jcuType,
          jcuState: '正常'
        }
      }).then(res => {
        if (res.data.result) {
          this.order.orderChannel = res.data.result
        }
      }).catch(err => {
        this.isError(err,this)
      })
    },
    jcuGroupTypeChange(id){//群体分类改变时
      this.order.params.jppPlanName = ''
      this.order.params.jppId = ''
      this.order.params.jcuAllowCredit = ''
      this.order.params.jcuMaxCredit = ''
      this.$axios({
        method: 'get',
        url: `/hotel/channelUnit/${id}`,
      }).then(res => {
        if (res.data.result.jcuGroupType) {
          console.log(res.data.result)
          this.order.orderChannel = res.data.result.channelUnitList
          this.order.params.jcuGroupType = res.data.result.jcuGroupType
          this.order.params.jcuId=res.data.result.jcuId
          this.order.params.jppPlanName = res.data.result.jppPlanName
          this.order.params.jppId = res.data.result.jppId
          this.order.params.jcuAllowCredit = res.data.result.jcuAllowCredit == '1' ? '是' : '否'
          this.order.params.jcuMaxCredit = res.data.result.jcuMaxCredit
          this.calcAmount()
        }
      }).catch(err => {
        this.isError(err,this)
      })
    },
    cancelRetain(){//取消保留
      this.$axios({
        method: 'put',
        url: `/hotel/shortRentContract/${this.order.dataOrder.jsrcId}/updateRetain`,
      }).then(res => {
        this.$message.success('取消成功')
        this.order.dialogOrderDetails = false
        if (this.bookingOrder.dialogBookingOrder) {
          this.getBookingOrder()
        }
        this.getDataList()
      }).catch(err => {
        this.isError(err,this)
      })
    },
    orderReceipt(){//接单
      this.$axios({
        method: 'put',
        url: `/hotel/shortRentContract/${this.order.dataOrder.jsrcId}/receipt`,
      }).then(res => {
        this.$message.success('接单成功')
        this.order.dialogOrderDetails = false
        this.getDataList()
      }).catch(err => {
        this.isError(err,this)
      })
    },
    cancelReserve(){//退定
      this.$axios({
        method: 'put',
        url: `/hotel/shortRentContract/${this.order.dataOrder.jsrcId}/cancellation`,
      }).then(res => {
        this.$message.success('退定成功')
        this.order.dialogOrderDetails = false
        this.getDataList()
      }).catch(err => {
        this.isError(err,this)
      })
    },
    async selectTypeOccupancy() {
      if (this.order.params.jsrcTypeOccupancy == '钟点客房') {
        await this.getTimeOptions()
      }
      this.calcAmount()
    },
    calcAmount(){
      this.inquireRoom2()
      let obj = {
        hsRoomType: this.chanceTime.rowData.diagramRoomType,
        jppId: this.order.params.jppId,
        jsrcId: this.order.dataOrder.jsrcId,
        jsrcBeginTime: this.order.dialogOrderDetails?this.order.params.jsrcBeginTime:this.retain.shortRentContractPARM.jsrcBeginTime,
        jsrcEndTime: this.order.dialogOrderDetails?this.order.params.jsrcEndTime:this.retain.shortRentContractPARM.jsrcEndTime,
        jsrcTypeOccupancy: this.order.params.jsrcTypeOccupancy,
      }
      this.$axios({
        method: 'get',
        url: `/hotel/shortRentContract/calculatePrice`,
        params: obj
      }).then(res => {
        for (let i in res.data.result) {
          for (let k in this.order.params) {
            if (i == k) {
              this.order.params[k] = res.data.result[i]
            }
          }
        }
        this.order.payable = this.order.params.jsrcTotalPrice
      }).catch(err => {
        this.isError(err,this)
      })
    },
    putReceivables(state,dialog){//收款小弹窗
      if(this.submitForm([dialog],this)){
        if(dialog == 'extend'){
          this.receivables.receivable = Math.abs(this.extend.params.payableMoney)
        } else if (dialog == 'checkOut') {
          this.receivables.receivable = Math.abs(this.checkOut.params.refundedMoney)
        } else if (dialog == 'chanceRoom') {
          this.receivables.receivable = Math.abs(this.chanceRoom.params.changeHouseMoney)
        } else if (dialog == 'checkin') {
          this.receivables.receivable = Math.abs(this.order.params.jsrcTotalPrice)
        }
        this.receivables.state = state
        this.receivables.title = state == 1 ? '现金收银' : state == 2 ? '扫码收银' : state == 3 ? '台卡收银' : ''
        this.receivables.dialog = dialog
        this.receivables.dialogReceivables = true
        this.$nextTick(() => {
          if (this.receivables.state == 2) {
            this.$refs.paymentCode.focus()
          } else if (this.receivables.state == 1) {
            this.$refs.netReceipts.focus()
          } else if (this.receivables.state == 3) {
            console.log(this.$refs.receivable)
            this.$refs.receivable.focus()
          }
        })
      }
    },
    netReceiptsChange(){//实收金额改变时
      if (this.receivables.receivable + 99.99 < this.receivables.netReceipts) {
        this.receivables.dialogAlert = true
      } else {
        this.receivables.dialogAlert = false
      }
      if (this.receivables.netReceipts) {
        this.receivables.giveChange = Math.round((this.receivables.netReceipts - this.receivables.receivable) * 100)/100;
      } else {
        this.receivables.giveChange = ''
      }
    },
    receivablesSave(state){//收银提交
      console.log(this.order.params)
      if(this.submitForm(['receivables'],this)){
        if (state == 1 && (((Number(this.receivables.receivable) + 100) < Number(this.receivables.netReceipts)) || (Number(this.receivables.receivable) > Number(this.receivables.netReceipts)))) {
          this.$message.warning('请检查实收金额！')
          return
        }
        if (this.receivables.dialog == 'extend') {//续住
          if(this.submitForm(['extend'],this)){
            this.extend.params.payType = state
            this.extend.params.authCode = state == 2 ? this.receivables.paymentCode : ''
            this.$axios({
              method: 'put',
              url: `/hotel/shortRentContract/${this.order.dataOrder.jsrcId}/continuedResidence`,
              params: this.extend.params
            }).then(res => {
              this.$message.success('续住成功')
              this.receivables.dialogReceivables = false
              this.extend.dialogExtend = false
              this.order.dialogOrderDetails = false
              this.cleanReceivables(state)
              this.getDataList()
            }).catch(err => {
              this.isError(err,this)
            })
          }
        } else if (this.receivables.dialog == 'checkOut') {//退房
          if(this.submitForm(['checkOut'],this)){
            this.checkOut.params.payType = state
            this.checkOut.params.authCode = state == 2 ? this.receivables.paymentCode : ''
            this.putCheckOut(state)
          }
        } else if (this.receivables.dialog == 'chanceRoom') {//换房
          if(this.submitForm(['chanceRoom'],this)){
            this.chanceRoom.params.payType = state
            this.chanceRoom.params.authCode = state == 2 ? this.receivables.paymentCode : ''
            this.$axios({
              method: 'put',
              url: `/hotel/shortRentContract/${this.order.dataOrder.jsrcId}/exchangeHouses`,
              params: this.chanceRoom.params
            }).then(res => {
              this.$message.success('换房成功')
              this.receivables.dialogReceivables = false
              this.chanceRoom.dialogOrderChanceRoom = false
              this.order.dialogOrderDetails = false
              if (this.bookingOrder.dialogBookingOrder) {
                this.getBookingOrder()
              }
              this.cleanReceivables(state)
              this.getDataList()
            }).catch(err => {
              this.isError(err,this)
            })
          }
        } else if (this.receivables.dialog == 'checkin') {//入住
          this.order.params.payType = state
          this.order.params.authCode = state == 2 ? this.receivables.paymentCode : ''
          if (this.order.params.popTmpPARMS.length == 0) {
            this.order.params.popTmpPARMS[0] = {}
          }
          this.receivables.loading = true
          console.log(this.order.params)
          console.log(this.order.params.popTmpPARMS[0].jsrrChannelId)
          this.$axios({
            method: 'post',
            url: `/hotel/shortRentContract`,
            data: this.order.params
          }).then(res => {
            this.receivables.loading = false
            this.$message.success('入住成功')
            this.receivables.dialogReceivables = false
            this.order.dialogOrderDetails = false
            this.cleanReceivables(state)
            this.getDataList()
            this.printConfirm('已住', res.data.result)
          }).catch(err => {
            this.receivables.loading = false
            this.isError(err,this)
          })
        }
      }
    },
    checkInReceivables(state,dialog){//入住收款小弹窗
    console.log(state)
    if(this.order.params.jcuGroupType==''){
      this.$message({
        message: '请选择群体分类',
        type: 'warning'
      });
      return
    }
    console.log(this.order.params.jppId)
    console.log(this.order.params)
      if(this.submitForm(['order'],this)){
        this.addPeople('入住')
        console.log('进入')
        this.receivables.receivable = this.order.params.jsrcTotalPrice
        if (state == 4 || state == 5) {
          this.order.params.payType = state
          this.order.params.authCode = state == 2 ? this.receivables.paymentCode : ''
          this.order.params.popTmpPARMS[0].jsrrChannelId = this.order.params.jcuId//渠道id
          this.order.params.popTmpPARMS[0].jsrrVipLevel = this.order.params.jcuMemberLevel//会员级别
          if (state == 4) {
            this.order.params.jsrcTotalPrice = this.order.params.jsrcAmountPayable
          }
          console.log(state)
          this.$axios({
            method: 'post',
            url: `/hotel/shortRentContract`,
            data: this.order.params
          }).then(res => {
            this.$message.success('入住成功')
            this.receivables.dialogReceivables = false
            this.order.dialogOrderDetails = false
            if (this.bookingOrder.dialogBookingOrder) {
              this.getBookingOrder()
            }
            this.getDataList()
          }).catch(err => {
            this.isError(err,this)
          })
        } else {
          console.log(state)
          this.receivables.state = state
          this.receivables.title = state == 1 ? '现金收银' : state == 2 ? '扫码收银' : state == 3 ? '台卡收银' : ''
          this.receivables.dialog = dialog
          this.receivables.dialogReceivables = true
          this.order.params.popTmpPARMS[0].jsrrChannelId = this.order.params.jcuId//渠道id
          this.order.params.popTmpPARMS[0].jsrrVipLevel = this.order.params.jcuMemberLevel//会员级别
          this.$nextTick(() => {
            if (this.receivables.state == 2) {
              this.$refs.paymentCode.focus()
            } else if (this.receivables.state == 1) {
              this.$refs.netReceipts.focus()
            } else if (this.receivables.state == 3) {
            console.log(this.$refs.receivable)
            this.$refs.receivable.focus()
          }
          })
        }
      }
    },
    cleanReceivables(state){//清空收银
      if (state == 4 || state == 5 || state == 6) {
        this.submitForm(['checkOut'],this)
      } else {
        this.resetForm(['receivables'],this)
      }
      this.receivables.dialogAlert = false
      this.receivables.dialogReceivables = false
      this.receivables.state = ''
      this.receivables.title = ''
      this.receivables.dialog = ''
      this.receivables.receivable = ''//应收
      this.receivables.paymentCode = ''//付款码
      this.receivables.netReceipts = ''//实收
      this.receivables.giveChange = ''//找零
    },
    async startFun(){ //读取身份证按钮
      this.order.params.popName = ''  //名字
      this.order.params.popTelephone = ''
      this.order.params.popNameRemark = ''
      this.order.params.popIdcard = ''//身份证号码
      this.order.params.popIdcardType = ''
      this.order.params.popBirth = ''//生日
      this.order.params.popIdcardAddress = ''//户籍地址
      this.order.params.popNation = ''//民族
      this.order.params.popImgPath = ''
      this.order.params.jcuMemberLevel = ''
      this.popIdcardJson = {}
      this.order.addPeople = false
      this.order.readCardFlag = false
      this.order.params.checked = false

      new Device().startFun()  //读取身份证触发函数
      setTimeout(() => {
        console.log(window.massageList)
        let studentMsg = window.massageList && window.massageList.Certificate
        console.log(studentMsg)
        this.popIdcardJson = window.massageList
        window.massageList = {}
        if (studentMsg == undefined) {
          return
        }
        this.order.readCardFlag = true
        console.log(studentMsg.Birthday)
        this.order.params.popName = studentMsg.Name  //名字
        this.order.params.popIdcard = studentMsg.IDNumber//身份证号码
        this.order.params.popIdcardType = '身份证/临时身份证/户口本'
        if (studentMsg.Birthday.length == 8) {//生日
          this.order.params.popBirth = `${studentMsg.Birthday.substr(0,4)}-${studentMsg.Birthday.substr(4,2)}-${studentMsg.Birthday.substr(6,2)}`
        } else if (studentMsg.Birthday.length == 10) {
          this.order.params.popBirth = `${studentMsg.Birthday.substr(0,4)}-${studentMsg.Birthday.substr(5,2)}-${studentMsg.Birthday.substr(8,2)}`
        }
        this.order.params.popIdcardAddress = studentMsg.Address//户籍地址
        this.order.params.popNation = studentMsg.Nation//民族
        this.order.params.popImgPath = `data:image/png;base64,${studentMsg.Base64Photo}`//证件照
        this.order.params.popSex = studentMsg.Sex
        this.submitForm(['order'],this)
        this.$axios({
          method: 'get',
          url: `/hotel/shortRentRenter/${this.order.params.popIdcard}/getByIdCard`
        }).then(res=>{
          let result = res.data.result
          if(JSON.stringify(result) != '{}'){
          console.log(result)
            this.order.params.jcuType = result.jcuType//渠道类型
            this.order.params.jcuGroupType = result.jsrrChannelId//群体分类
            // this.order.params.jcuGroupType = 21//群体分类
            if(this.order.params.popTmpPARMS.length == 0){
              this.jcuGroupTypeChange(result.jsrrChannelId)
            }
            this.order.params.popTelephone = result.popTelephone
            this.order.params.popNameRemark = result.popNameRemark
            this.order.params.jsrrCheckInNum = result.jsrrCheckInNum ? result.jsrrCheckInNum : 0
            this.order.params.jspCredit = result.jspCredit ? result.jspCredit : 0
            this.order.params.jcuMemberLevel = result.jsrrVipLevel ? result.jsrrVipLevel : ''
            this.submitForm(['order'],this)
          } else {
            this.getDefaultChannel()
          }
          this.order.peopleInformation = result
          this.order.addPeople = true
        }).catch(err=>{
          this.isError(err,this)
        })
      },1000)
    },
    addPeople(type){//添加信息
      if(this.submitForm(['order'],this)){
        let obj = {
          popBirth: this.order.params.popBirth,//生日
          popIdcard: this.order.params.popIdcard,//身份证号码
          popIdcardAddress: this.order.params.popIdcardAddress,//户籍地址
          popName: this.order.params.popName,//名字
          popNameRemark: this.order.params.popNameRemark,//备注
          popNation: this.order.params.popNation,//民族
          popTelephone: this.order.params.popTelephone,//手机号
          popIdcardType: this.order.params.popIdcardType,
          popSex: this.order.params.popSex,
          popImgPath: this.order.params.popImgPath,
          jppId:this.order.params.jppId
        }
        if (JSON.stringify(this.popIdcardJson) != '{}') {
          obj.popIdcardJson = JSON.stringify(this.popIdcardJson)
        }
        if (this.order.params.popTmpPARMS.length == 0) {
          this.order.params.popTmpPARMS.push(obj)
          this.$nextTick(() => {
            this.$refs.fellowResidents.setCurrentRow(obj)
          })
        } else {
          let flag = this.order.params.popTmpPARMS.some((item, index, array) =>{
            return item.popIdcard == this.order.params.popIdcard
          })
          if (flag) {
            if (type != '入住') {
              this.$message.warning('请勿重复添加已有人员')
            }
          } else {
            this.order.params.popTmpPARMS.push(obj)
            this.$nextTick(() => {
              this.$refs.fellowResidents.setCurrentRow(obj)
            })
          }
        }
      }
    },
    closeDialogDetails(){//关闭订单详情
      // this.resetForm(['order'],this)
      this.order.params = {
        address: '',
        jsrcState: '',
        checked: false,
        popName: '',//客户姓名
        popTelephone: '',//手机号码
        popNameRemark: '',//备注
        popIdcard: '',//证件号码
        popIdcardType: '',//证件类型
        popBirth: '',//生日
        popIdcardAddress: '',//户籍地址
        popNation: '',//民族
        jsrrCheckInNum: '',//入住次数
        jcuType: '门店',//渠道类型
        jcuGroupType: '',//群体分类
        popImgPath: '',
        popSex: '',
        jppPlanName: '',//价格方案
        jppId: '',
        jcuAllowCredit: '',//允许挂账
        jcuMaxCredit: '',//挂账额度
        jcuMemberLevel: '',//会员级别
        jsrcBeginTime: '',//预定时间
        jsrcEndTime: '',//退房时间
        jsrcTotalDays: '',//总天数
        jsrcAmountPayable: '',//总房价
        jsrcDailyPrice: '',//日均价格
        jsrcDeposit: '',//客房押金
        jsrcOrderSource: '上门客户',//订单来源
        jsrcTotalPrice: '',//折后金额
        jsrcActualOccupancyTime: '',//入住时间
        jsrcActualDepartureTime: '',//退房时间
        jsrcPaymentMethod: '',//付款方式
        jsrcTypeOccupancy: '普通客房',//入住类型
        jsrcRemarks: '',//订单备注
        jspCredit: '',//签单人挂账额度
        jsrcFirstPay: 0,//已付金额
        jsrcArrears: 0, //欠费金额
        diagramOrderState: 1,
        popTmpPARMS: [],
        doorCardPARMS: [],
        payType: '',
        authCode: '',
        hsId: '',
        discountNum: 1,
        jsrcId: '',
        jtoId: null,
        offlinePassword: ''
      }
      this.order.reserve = {
        popName: '',//预订人
        popTelephone: '',//预订电话
        jsrcBookingWay: '',//预订途径
      }
      this.order.payable = 0
      this.order.payactualPaymentable = 0
      this.order.deviceInfo = [
        {
          deviceType: '空调',
          url: require('../../assets/ic_dt_aircondition_icon_gray.png'),
          html: '<p>该房间尚未绑定相关设备</p>',
        },{
          deviceType: '门锁',
          url: require('../../assets/doorLock_gray.png'),
          html: '<p>该房间尚未绑定相关设备</p>',
        },{
          deviceType: '插卡面板',
          url: require('../../assets/baka.png'),
          html: '<p>该房间尚未绑定相关设备</p>',
        },{
          deviceType: '窗帘',
          url: require('../../assets/ic_dt_curtain_icon_gray.png'),
          html: '<p>该房间尚未绑定相关设备</p>',
        },{
          deviceType: 'SOS报警器',
          url: require('../../assets/ic_dt_safe_sos_icon_gray.png'),
          html: '<p>该房间尚未绑定相关设备</p>',
        },{
          deviceType: '电子门牌',
          url: require('../../assets/ic_dt_safe_doorbell_icon_gray.png'),
          html: '<p>该房间尚未绑定相关设备</p>',
        },
      ],
      this.order.params.popTmpPARMS = []
      this.order.dataEquipment = []
      this.order.dataFollowUp = []//跟进
      this.order.dataRemind = []//提醒
      this.order.dataOrder = []//订单信息
      this.order.checkInBtn = false//入住按钮
      this.order.addPeople = false//入住按钮
      this.order.readCardFlag = false//读取身份证按钮
      this.order.peopleInformation = {}
      this.order.dataVacantRoom = []
      this.order.orderChannel = []//群体分类下拉菜单
      this.order.retainCheckOutLimit = {}//保留退房日期限制
      this.order.discountApplication = true
      this.chanceTime.start = ''
      this.chanceTime.end = ''
    },
    //入住
      addChanceTime(row){
        this.queryTime.hsId=parseInt(row.hsId)
        console.log(this.queryTime)
        this.popIdcardJson = {}
        if (typeof this.authe.Hotel_Core_RoomStateDiagram_OrderDetails == 'undefined') {
          this.$message.warning('无权限操作')
          return
        }
        this.chanceTime.start = moment().format(`YYYY-MM-DD ${this.setupList.jsrsuCheckInTime}:00`)
        this.quickSelection(1)
        console.log(moment().format('YYYY-MM-DD'))
        this.chanceTimeStartChange()
        this.order.params.diagramState = row.diagramState
        this.chanceTime.rowData = row
        let a = row.diagramCommunity ? `${row.diagramCommunity}\u3000` : ''
        let b = row.diagramRoomType ? `${row.diagramRoomType}\u3000` : ''
        let c = row.diagramDoorplateno ? `${row.diagramDoorplateno}` : ''
        this.order.params.address = a + b + c
        this.order.params.jsrcState = '空房'
        this.order.params.jcuType = '门店'//渠道类型
        // this.jcuTypeChange()
        setTimeout(() => {
          this.chanceTime.dialogChanceTime = true
        },500)
      },
      quickSelection(days) {
        this.chanceTime.end = moment(this.chanceTime.start).add(days,'days').format(`YYYY-MM-DD ${this.setupList.jsrsuCheckOutTime}:00`)
        this.inquireRoom()
     },
      async chanceTimeStartChange(){
        this.inquireRoom()
        console.log(this.chanceTime.start)
        console.log(this.chanceTime.end)
        if (this.chanceTime.start) {
          let oldCheckOutTime = new Date((this.chanceTime.start).substr(0,10)).getTime()
          let asd=await this.getDay(new Date((this.chanceTime.start).substr(0,10)),30);
          this.chanceTime.checkOutLimit = {//日期限制
            disabledDate(time) {
              // return time.getTime() < new Date(oldCheckOutTime).getTime();
              return time.getTime() > new Date(asd)
            }
          }
        }
      },
      async getDay(today,day) {
        var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
        today.setTime(targetday_milliseconds); //注意，这行是关键代码
        var tYear = today.getFullYear();
        var tMonth = today.getMonth();
        var tDate = today.getDate();
        tMonth =await this.doHandleMonth(tMonth + 1);
        tDate =await this.doHandleMonth(tDate);
        return tYear + "-" + tMonth + "-" + tDate;
      },
      doHandleMonth(month) {
        var m = month;
        if (month.toString().length == 1) {
            m = "0" + month;
        }
        return m;
      },
      chanceTimeEndChange(){
        this.inquireRoom()
      },
      // 判断退房时间是否小于入住时间
      judgeDete(start,end){
        let startMonth=new Date(start).getMonth()
        let startDay=new Date(start).getDate()
        let endMonth=new Date(end).getMonth()
        let endDay=new Date(end).getDate()
        console.log(Number(start))
        console.log(`end:${endMonth}:${endDay}`)
        console.log(endMonth >= startMonth || endDay>startDay)
        // if(  || endDay>startDay){
        //   return false
        // }else{
        //   return true
        // }
        if(endMonth > startMonth){
          return false
        }else if(endMonth == startMonth){
          if(endDay<startDay){
            return true
          }
        }
      },
      chanceTimeSave(){//空房入住选择时间保存
        console.log(this.judgeDete(this.chanceTime.start,this.chanceTime.end))
        if(this.judgeDete(this.chanceTime.start,this.chanceTime.end)){
          this.$message.error('退房时间不得小于入住时间');
          return
        }
        // console.log("start:"+this.chanceTime.start)
        // console.log("start:"+new Date(this.chanceTime.start) )
        // console.log("end:"+ this.chanceTime.end)
        if(this.submitForm(['chanceTime'],this)){
          this.getDefaultChannel()
          this.order.checkInBtn = true
          let start = (this.chanceTime.start).substr(0,10).replace(/-/g, "")
          let time = new Date()
          time.setTime(time.getTime())
          let year = time.getFullYear()
          let month = (time.getMonth()+1) < 10 ? `0${(time.getMonth()+1)}` : (time.getMonth()+1)
          let date = time.getDate() < 10 ? `0${time.getDate()}` : time.getDate()
          let today = `${year}${month}${date}`
          if (start == today) {
            this.order.params.hsId = this.chanceTime.rowData.diagramHsId
            this.order.params.jsrcBeginTime = this.chanceTime.start
            this.order.params.jsrcEndTime = this.chanceTime.end
            this.order.payable = this.order.params.jsrcTotalPrice
            this.order.dialogOrderDetails = true
          } else {
            this.retain.shortRentContractPARM.hsId = this.chanceTime.rowData.diagramHsId
            this.retain.shortRentContractPARM.jsrcBeginTime = this.chanceTime.start
            this.retain.shortRentContractPARM.jsrcEndTime = this.chanceTime.end
            this.retain.dialogRetainOrder = true
          }
          this.chanceTime.dialogChanceTime = false
        }
      },
      closeDialogChanceTime(){//关闭空房入住选择时间
        this.resetForm(['chanceTime'],this)
        this.chanceTime.start = ''
        this.chanceTime.end = ''
      },
    //保留
      retainOrderSave(){
        if(this.submitForm(['retain'],this)){
          this.retain.shortRentContractPARM.jsrcTotalDays = this.order.params.jsrcTotalDays
          this.retain.shortRentContractPARM.jsrcDailyPrice = this.order.params.jsrcDailyPrice
          this.retain.shortRentContractPARM.jsrcTotalPrice = this.order.params.jsrcTotalPrice
          this.retain.shortRentContractPARM.jsrcAmountPayable = this.order.params.jsrcAmountPayable
          this.retain.shortRentContractPARM.jsrcDeposit = this.order.params.jsrcDeposit
          let arr = [
            {
              popName: this.retain.shortRentContractPARM.popName,
              popIdcard: this.retain.shortRentContractPARM.popIdcard,
              popTelephone: this.retain.shortRentContractPARM.popTelephone
            }
          ]
          this.retain.shortRentContractPARM.jsrcPeople = JSON.stringify(arr)
          this.$axios({
            method: 'post',
            url: `/hotel/shortRentContract/retain`,
            data: this.retain.shortRentContractPARM
          }).then(res => {
            this.$message.success('保留成功，请在【预订订单】中进行查看！')
            this.retain.dialogRetainOrder = false
          }).catch(err => {
            this.isError(err,this)
          })
        }
      },
      closeDialogRetainOrder(){
        this.resetForm(['retain'],this)
        this.retain.shortRentContractPARM = {
          hsId: '',
          jsrcTotalDays: 0,
          jsrcDailyPrice: 0,
          jsrcTotalPrice: 0,
          jsrcAmountPayable: 0,
          jsrcDeposit: 0.01,
          jsrcBeginTime: '',
          jsrcEndTime: '',
          jsrcPeople: '',
          popName: '',
          jsrcState: '保留',
          popTelephone: '',
          popIdcard: '',
          jsrcRemarks: '',
        }
      },
    //续住
      extendRoom(){//续住窗口
        console.log(this.order.params.jsrcArrears)
        if(this.order.params.jsrcArrears>0){
          this.$confirm('房客已欠费请先退房重新入住?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.checkOutRoom()
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            })
          })

        }else{
          this.extend.checkOutTime = this.order.dataOrder.jsrcEndTime
          this.extend.params.dailyPrice = this.order.params.jsrcDailyPrice
          this.extend.params.arrears = this.order.params.jsrcArrears
          this.extend.params.payableMoney = parseFloat(this.order.params.jsrcArrears) // + parseF
          let oldCheckOutTime = new Date(this.extend.checkOutTime).getTime()
          this.extend.extendLimit = {//日期限制
            disabledDate(time) {
              return time.getTime() < new Date(oldCheckOutTime).getTime();
            }
          }
          this.extend.dialogExtend = true
        }

      },
      newEndTimeChange(){//新退房时间改变时
        console.log('时间修改')
        console.log((new Date(this.extend.params.newEndTime).getTime() - new Date(this.extend.checkOutTime).getTime())/(1000*60*60*24))
        this.extend.checkOutTime = this.extend.checkOutTime.slice(0,10)
        this.extend.params.newTotalDay = (new Date(this.extend.params.newEndTime).getTime() - new Date(this.extend.checkOutTime).getTime())/(1000*60*60*24)
        this.extend.params.payableMoney = this.$JsMath.accMul(this.extend.params.newTotalDay,this.extend.params.dailyPrice)
        this.receivables.receivable = this.extend.params.payableMoney
        this.extend.params.newEndTime = `${this.extend.params.newEndTime} ${this.setupList.jsrsuCheckOutTime}:00`
      },
      closeDialogExtend(){//续住关闭清空
        this.resetForm(['extend'],this)
        this.extend.params = {
          newEndTime: '',//新退房时间
          newTotalDay: '',//总共天数
          dailyPrice: '',//日均价格
          payableMoney: '',//应付金额
          payType: '',
          authCode: '',
        }
        this.extend.checkOutTime = ''//退房时间
        this.extend.extendLimit = {}//日期限制
      },
    //退房
      checkOutRoom(){//退房
      console.log(this.order.params)
        this.checkOut.params.amountOfArrears = this.order.dataOrder.jsrcArrears
        this.checkOut.params.checkOutDeposit = this.order.dataOrder.jsrcDeposit
        this.checkOut.consumptionAmount = Math.round((this.order.dataOrder.jsrcArrears + this.checkOut.params.additionalSum) * 100)/100;
        let jppId = ''

        for (let i of this.order.params.popTmpPARMS) {
          if (this.order.dataOrder.popId == i.popId) {
            jppId = i.jppId
            console.log(i.jppId)
            break
          }
        }
        // console.log(jppId)
        this.$axios({
          method: 'get',
          url: `/hotel/shortRentContract/refundOfRoomCharge`,
          params: {
            // hsRoomType: this.order.dataOrder.hsRoomType,
            // jppId: jppId,
            jsrcId: this.order.dataOrder.jsrcId,
          }
        }).then(res => {
          this.checkOut.params.refundedRentMoney = res.data.result
          this.checkOut.params.jppId = jppId                // 客房押金+应退房费-消费金额
          this.checkOut.params.refundedMoney = Math.round((this.checkOut.params.checkOutDeposit + this.checkOut.params.refundedRentMoney - this.checkOut.consumptionAmount) * 100)/100
          console.log(this.checkOut.params.refundedRentMoney)
          console.log(this.checkOut.params.refundedMoney)
          if(this.checkOut.params.refundedRentMoney<0){
            this.roomrate=false
            this.checkOut.params.absrefundedRentMoney=Math.abs(this.checkOut.params.refundedRentMoney)
          }else{
            this.roomrate= true
          }
          if(this.checkOut.params.refundedMoney<0){
            this.money=false
            this.checkOut.params.absrefundedMoney=Math.abs(this.checkOut.params.refundedMoney)
          }else{
            this.money=true
          }
        //  if(this.checkOut.params.refundedMoney<0){
        //     this.money=!this.money
        //     this.checkOut.params.refundedMoney=Math.abs(this.checkOut.params.refundedMoney)
        //   }else{
        //     this.money=!this.money
        //   }
          let myDate = Date.parse(new Date())
          let end = new Date(this.order.params.jsrcEndTime).getTime()
          if (end > myDate) {
            this.$confirm('未到预离时间，确定提前退房？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.checkOut.dialogCheckOutRoom = true
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消'
              })
            })
          } else {
            this.checkOut.dialogCheckOutRoom = true
          }
        }).catch(err => {
          this.isError(err,this)
        })
      },
      checkOut456(state,dialog){//退款456
        
        let arr = []
        if (state == 6){
          arr = ['checkOut','checkOutForm']
        } else {
          arr = ['checkOut']
        }
        if(this.submitForm(arr,this)){
          this.checkOut.params.payType = state
          this.putCheckOut(state)
        }
      },
      checkOutCleaningChange(){//清洁
        this.checkOut.params.usersId = this.checkOut.cleaning[2]
      },
      consumptionChange(){//附加消费改变
        for (let i of JSON.parse(this.setupList.jsrsuServiceCharge)) {
          if (this.checkOut.consumption == i.popservice) {
            this.checkOut.unitPrice = i.popcharge
            break
          }
        }
      },
      calculatedAmount(){//计算金额
        this.checkOut.params.additionalSum = 0
        this.checkOut.consumptionAmount = 0
        for(var i=0;i<this.checkOut.dataTable.length;i++){
          this.checkOut.params.additionalSum+=parseInt(this.checkOut.dataTable[i].popcharge)
        }
        this.checkOut.consumptionAmount = this.checkOut.params.amountOfArrears + this.checkOut.params.additionalSum
        this.checkOut.params.refundedMoney = this.checkOut.params.checkOutDeposit + this.checkOut.params.refundedRentMoney - this.checkOut.consumptionAmount    
      },
      addService(){//添加服务
        if(this.checkOut.unitPrice == ''){
          this.$message({
            message: '请选择一个附加消费',
            type: 'warning'
          });
          return
        }
        for (let i of JSON.parse(this.setupList.jsrsuServiceCharge)) {
          if (this.checkOut.consumption == i.popservice) {
            this.checkOut.dataTable.push(i)
            break
          }
          
        }
        this.calculatedAmount()
      },
      deleteService(indexTable){//删除服务
        this.checkOut.dataTable = this.checkOut.dataTable.filter((item, index, arr) => { // item为数组中的元素，index为下标，arr为目标数组
          return index != indexTable
        })
        if (this.checkOut.dataTable.length > 0){
          this.calculatedAmount()
        } else {
          this.checkOut.params.additionalSum = 0
          this.checkOut.consumptionAmount = 0
          this.calculatedAmount()
        }
      },
      putCheckOut(state){//退房请求
        if(this.submitForm(['checkOut'],this)){
          this.checkOut.params.payType = state
          this.checkOut.params.additionalDescription = JSON.stringify(this.checkOut.dataTable)
          this.checkOut.params.refundedMoney = Math.abs(this.checkOut.params.refundedMoney)
          let params = this.checkOut.params;
          params.amountOfArrears = Math.abs(params.amountOfArrears)
          params.additionalSum = Math.abs(params.additionalSum)
          params.checkOutDeposit = Math.abs(params.checkOutDeposit)
          params.refundedMoney = Math.abs(params.refundedMoney)
          params.amountReceived = Math.abs(params.amountReceived)
          this.$axios({
            method: 'put',
            url: `/hotel/shortRentContract/${this.order.dataOrder.jsrcId}`,
            params: params
          }).then(res => {
            this.$message.success('退房成功')
            this.receivables.dialogReceivables = false
            this.checkOut.dialogCheckOutRoom = false
            this.order.dialogOrderDetails = false
            this.cleanReceivables(state)
            this.getDataList()
            this.printConfirm('已住', this.order.dataOrder.jsrcId)
          }).catch(err => {
            this.isError(err,this)
          })
        }
      },
      checkOutConfirm() {
        if(this.submitForm(['checkOut'],this) ){
          this.checkOut.popoverCheckOut = true
        }
      },
      closePopoverCheckOut() {
        this.resetForm(['checkOutForm'],this)
        this.checkOut.params.password = ''
      },
      closeDialogCheckOutRoom(){//退房关闭清空
        this.resetForm(['checkOut'],this)
        this.checkOut.params = {
          amountOfArrears: 0.0,//欠费金额
          additionalSum: 0.0,//附加金额
          checkOutDeposit: 0.0,//客房押金
          refundedRentMoney: 0.0,//应退房费
          refundedMoney: 0.0,//应退金额
          amountReceived: 0.0,//实收金额
          payType: '',
          usersId: '',//用户
          additionalDescription: '',//附属信息（附加消费具体数据）
          authCode: '',
          jppId: '',
          password: '',
        }
        this.checkOut.dialogCheckOutRoom = false
        this.checkOut.popoverCheckOut = false
        this.checkOut.cleaning = ''
        this.checkOut.consumption = ''//附加消费
        this.checkOut.unitPrice = ''//附加单价
        this.checkOut.consumptionAmount = 0//消费金额
        this.checkOut.dataTable = []
      },
    //换房
      orderChanceRoom(){//换房
        this.chanceRoom.roomType = this.order.dataOrder.hsRoomType
        this.roomTypeChange()
        this.chanceRoom.dialogOrderChanceRoom = true
      },
      roomTypeChange(){//查空房
        this.chanceRoom.params.hsId = ''
        this.$axios({
          method: 'get',
          url: `/hotel/house4store/vacantRoom`,
          params: {
            startTime: this.order.dataOrder.jsrcBeginTime,
            endTime: this.order.dataOrder.jsrcEndTime,
            roomType: this.chanceRoom.roomType,
          }
        }).then(res => {
          if (res.data.result && res.data.result.length > 0) {
            for (let i of res.data.result) {
              let a = i.hsAddCommunity ? `${i.hsAddCommunity}\u3000` : ''
              let b = i.hsRoomType ? `${i.hsRoomType}\u3000` : ''
              let c = i.hsAddDoorplateno ? `${i.hsAddDoorplateno}` : ''
              i.address = a + b + c
            }
            this.order.dataVacantRoom = res.data.result
          } else {
            this.order.dataVacantRoom = []
          }
        }).catch(err => {
          this.isError(err,this)
        })
      },
      checkRoomsChange(){//可换房改变
        let jppId = ''
        if (this.order.params.diagramState != '留'){
          for (let i of this.order.params.popTmpPARMS) {
            if (this.order.dataOrder.popId == i.popId) {
              jppId = i.jppId
              break
            }
          }
        }
        this.$axios({
          method: 'get',
          url: `/hotel/shortRentContract/changeHousePrice`,
          params: {
            hsRoomType: this.chanceRoom.roomType,
            jppId: jppId,
            jsrcId: this.order.dataOrder.jsrcId,
          }
        }).then(res => {
          this.chanceRoom.params.changeHouseMoney = res.data.result
          this.receivables.receivable = res.data.result
        }).catch(err => {
          this.isError(err,this)
        })
      },
      chanceRoomCleaningChange(arr){//清洁
        this.chanceRoom.params.userId = arr[2]
      },
      orderChanceRoomSave(){
        this.receivables.dialog = 'chanceRoom'
        if(this.submitForm(['chanceRoom'],this)){
          this.chanceRoom.params.payType = ''
          this.chanceRoom.params.authCode = ''
          this.$axios({
            method: 'put',
            url: `/hotel/shortRentContract/${this.order.dataOrder.jsrcId}/exchangeHouses`,
            params: this.chanceRoom.params
          }).then(res => {
            this.$message.success('换房成功')
            this.receivables.dialogReceivables = false
            this.chanceRoom.dialogOrderChanceRoom = false
            this.order.dialogOrderDetails = false
            if (this.bookingOrder.dialogBookingOrder) {
              this.getBookingOrder()
            }
            this.getDataList()
          }).catch(err => {
            this.isError(err,this)
          })
        }
      },
      closeDialogOrderChanceRoom(){//关闭换房
        this.resetForm(['chanceRoom'],this)
        this.chanceRoom.params = {
          reason: '',//换房原因
          hsId: '',//可换客房
          user: '',//保洁人员
          cleanRepHopeTime: '',//保洁时间
          changeHouseMoney: 0,//补房差价
          payType: '',
          authCode: '',
        }
        this.chanceRoom.roomType = ''
      },
    //写跟进
      followUpTypeChange(){
        this.followUp.title = this.followUp.params.followUpType == '手动跟进' ? '操作' : '提醒内容'
      },
      orderFollowUpSave(){
        if(this.submitForm(['followUp'],this)){
          this.$axios({
            method: 'put',
            url: `/hotel/shortRentContract/${this.order.dataOrder.jsrcId}/writeFollowup`,
            params: this.followUp.params
          }).then(res => {
            this.orderDetails(this.chanceTime.rowData)
            this.$message.success('成功')
            this.followUp.dialogOrderFollowUp = false
          }).catch(err => {
            this.isError(err,this)
          })
        }
      },
      closeDialogOrderFollowUp(){
        this.resetForm(['followUp'],this)
        this.followUp.params = {
          followUpType: '手动跟进',//类型
          reminderTime: '',//提醒时间
          record: '',//操作
        }
        this.followUp.title = '操作'
      },
    //折扣
      addDiscount(){//折扣     
        this.discount.dialogDiscount = true
      },
      jtoDiscountChange(){//计算折扣金额
        this.order.params.discountNum = this.discount.temporaryOrderPARM.jtoDiscount/10
        console.log(Number(`0.${this.discount.temporaryOrderPARM.jtoDiscount}`))
        this.discount.temporaryOrderPARM.jtoDiscountPrice = Math.round((this.order.payable-this.order.params.jsrcDeposit) * this.order.params.discountNum * 100)/100;
      },
      discountCleaningChange(){//清洁
        this.discount.temporaryOrderPARM.jtoUserId = this.discount.temporaryOrderPARM.cleaning[2]
      },
      grantModeChange(){//授权方式改变
        this.resetForm(['discount'],this)
      },
      addDiscountSave(){//折扣保存
        if(this.submitForm(['discount'],this)){
          this.addPeople('入住')
          this.discount.temporaryOrderPARM.jtoHsId = this.order.params.hsId//对应挂单id（未租ID）
          this.discount.temporaryOrderPARM.jtoRoomType = this.order.params.jsrcTypeOccupancy//房型（普通客房、钟点客房）
          this.discount.temporaryOrderPARM.jtoTotalHousingPrice = this.order.params.jsrcTotalPrice//订单总价
          this.discount.temporaryOrderPARM.jtoDayPrice = this.order.params.jsrcDailyPrice//日均房价
          this.discount.temporaryOrderPARM.jtoAveragePrice = Math.round(this.discount.temporaryOrderPARM.jtoDiscountPrice / this.order.params.jsrcTotalDays * 100)/100
          this.discount.temporaryOrderPARM.jtoStatus = '处理中'//授权状态（处理中）     
          let obj = {
            jcuType: '',//渠道类型
            jcuId: '',//渠道id
            jsrrCheckInNum: '',//入住次数
            jsrcTotalDays: '',//总天数
            jsrcDailyPrice: '',//日均房价
            jsrcDeposit: '',//押金
            jsrcOrderSource: '上门客户',//订单来源
            jsrcAmountPayable: '',//总房价
            payable: this.order.payable,//应付金额
            jsrcTotalPrice: '',//实付金额（折后价格）
            jsrcTypeOccupancy: '',//入住类型
            jsrcRemarks: '',//订单备注
            jsrcBeginTime: '',//合约开始时间
            jsrcEndTime: '',//合约结束时间
            popTmpPARMS: []
          }
          for (let i in this.order.params) {
            for (let k in obj) {
              if (i == k || i == "jcuGroupType" && k == "jcuId") {
                obj[k] = this.order.params[i]
              }
            }
          }
          obj.jsrcTotalPrice = this.discount.temporaryOrderPARM.jtoDiscountPrice
          this.discount.temporaryOrderPARM.jtoShortInfo = JSON.stringify(obj)
          console.log(this.discount.temporaryOrderPARM)
          if (this.discount.grantMode == '远程授权') {          
            console.log(this.discount.temporaryOrderPARM)
            this.$axios({
              method: 'post',
              url: `/hotel/temporaryOrder`,
              data: this.discount.temporaryOrderPARM
            }).then(res => {
              this.order.params.jsrcTotalPrice =this.discount.temporaryOrderPARM.jtoDiscountPrice+this.order.params.jsrcDeposit
              this.$message.success('授权成功')
              this.discount.dialogDiscount = false
              this.order.dialogOrderDetails = false
            }).catch(err => {
              this.isError(err,this)
            })
          } else {
            console.log(this.discount.temporaryOrderPARM)
            this.$axios({
              method: 'get',
              url: `/hotel/shortRentContract/${this.discount.temporaryOrderPARM.password}/checkoutPassword`,
            }).then(res => {
              // this.order.params.jsrcTotalPrice = this.discount.temporaryOrderPARM.jtoDiscountPrice
              this.order.params.jsrcTotalPrice =this.discount.temporaryOrderPARM.jtoDiscountPrice+this.order.params.jsrcDeposit
              this.$message.success('授权成功')
              this.discount.dialogDiscount = false
            }).catch(err => {
              this.isError(err,this)
            })
          }
        }
      },
      closeDialogDiscount(){//折扣申请
        this.resetForm(['discount'],this)
        this.discount.temporaryOrderPARM = {
          userId: '',//授权人ID
          jtoRemark: '',//折扣原因
          password: '',//授权密码
          jtoDiscount: '',//折扣率
          jtoHsId: '',//对应挂单id（未租ID）
          jtoRoomType: '',//房型（普通客房、钟点客房）
          jtoTotalHousingPrice: '',//订单总价
          jtoDayPrice: '',//日均房价
          jtoDiscountPrice: '',//折扣后总价
          jtoAveragePrice: '',//折扣后均价
          jtoStatus: '处理中',//授权状态（处理中）
          jtoShortInfo: '',
          cleaning: ''
        }
        this.discount.grantMode = '现场授权'
        this.discount.dialogDiscount = false
      },
    //门卡授权
      grantDoorCard(){//门卡授权
        if(this.submitForm(['order'],this)){
          this.$axios({
            method: 'get',
            url: `/common2/doorCard/${this.order.params.hsId}/getLock`
          }).then(res=>{
            if(res.data.result.records.length == 0){
              this.$message.warning("房间不存在授权设备！");
            }else {
              this.doorCard.dialogDoorCard = true;
              this.doorCard.doorLocks = res.data.result.records;
            }
          }).catch(err=>{
            this.isError(err,this)
          })
        }
      },
      doorCardSave(){//门卡授权确定按钮
        if(Object.keys(this.doorCard.checkDevice).length == 0){
          this.$message.warning("请选择门锁！");
        }else {
          if(this.submitForm(['doorCard'],this)){
            let obj = {
              jdcHsId: this.order.params.hsId,
              jdcCardId: this.doorCard.doorCardPARM.jdcCardId,
              jdcDeviceId: this.doorCard.checkDevice.jourDeviceId, //锁的设备id ：deviceId
              jdcCardNum: this.doorCard.doorCardPARM.jdcCardNum,
              popIndex: this.doorCard.doorCardPARM.popIndex
            }
            this.order.params.doorCardPARMS.push(obj)
            this.$confirm(`是否继续授权门卡？`, '提示', {
              confirmButtonText: '是',
              cancelButtonText: '否',
              type: 'warning'
            }).then(() => {
            }).catch(() => {
              this.doorCard.dialogDoorCard = false
            });
          }
        }
      },
      closeDialogDoorCard(){//门卡授权关闭
        this.resetForm(['doorCard'],this)
        this.doorCard.checkDevice = {};
        this.doorCard.doorCardPARM = {
          authorizeType: 0,
          jourDeviceId: '',
          deviceIds: null,
          houseAddress: '',
          jdcCardId: '',
          jdcCardNum: '',
          jdcDeadlineTime: '',
          jdcHsId: null,
          jdcMaxUnlockingTimes: null,
          jdcUserId: null,
          popId: null,
          popIdcard: '',
          popName: '',
          popIndex: 0,
          startTime: '',
          encryptMode: '',
          sendMessage: ''
        }
      },

  //房间
    roomDetailDblclick(row) {
      this.roomDetailRowData = row
      this.roomDetailDialog = true
    },
    roomDetailClose() {
      this.getDataList()
      this.roomDetailDialog = false
    },
  //
    getDefaultChannel(){
      this.$axios({
        method: 'get',
        url: '/hotel/channelUnit/getDefaultChannel',
        params:{
          jcuState: '正常'
        }
      }).then(res=>{
        if (res.data.result) {
          this.order.params.jcuType = res.data.result.jcuType
          this.order.orderChannel = res.data.result.channelUnitList
          this.order.params.jsrcTypeOccupancy = '普通客房',
          this.order.params.jppPlanName = res.data.result.jppPlanName
          this.order.params.jppId = res.data.result.jppId
          this.order.params.jcuAllowCredit = res.data.result.jcuAllowCredit == '1' ? '是' : '否'
          this.order.params.jcuMaxCredit = res.data.result.jcuMaxCredit
          this.order.params.jcuGroupType = res.data.result.jcuId
          this.order.params.jcuId=res.data.result.jcuId
          this.calcAmount()
          console.log(this.order.params.jcuGroupType)
        }
      }).catch(err=>{
        this.isError(err,this)
      })
    },
    printConfirm(state, jsrcId){
      state  = state == '已住' ? '租客入住账单' : '租客退房账单'
      this.$confirm(`是否打印${state}?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.printBills(state, jsrcId)
      }).catch(() => {
        return;
      });
    },
    printBills(state, jsrcId){//退单打印
      if (state == '租客入住账单') {
        this.printedHistory.showState = true // 入住显示的内容
      } else if (state == '租客退房账单') {
        this.printedHistory.showState = false // 退房显示的内容
      }
      this.$axios({
        method: 'get',
        url: `/hotel/printedHistory`,
        params: {
          jhpJsrcId: jsrcId,
          jhpType: state
        }
      }).then(res => {
        this.printedHistory.dialogprintedHistory = true
        this.printedHistory.ticketInformation = res.data.result
      }).catch(err => {
        this.isError(err,this)
      })
    },
    openDialogMessagePush(){
      this.$axios({
        method: 'get',
        url: `/hotel/roomMessagePush`,
        params: {
          cursor : this.messagePush.params.cursor,
          limit : this.messagePush.params.limit
        }
      }).then(res => {
        this.messagePush.dataList = res.data.result.records
        this.messagePush.total = res.data.result.total
        this.messagePush.dialogMessagePush = true
      }).catch(err => {
        this.isError(err,this)
      })
    },
    closeDialogMessagePush(){
      this.messagePush = {
        dialogMessagePush: false,
        params: {
          limit: 5,//每页的条数
          cursor: 1,//初始页
        },
        dataList: [],
        total: 0,         //总条数
        pages: 0,      //总页数
      }
    },
    messagePushCurrent(cursor){
      this.messagePush.params.cursor=cursor
      this.openDialogMessagePush()
    },
    messagePushSize(size){
      this.messagePush.params.limit=size
      this.openDialogMessagePush()
    },
    updateMessageState(jrmpId,state){
      this.$axios({
        method: 'put',
        url: `/hotel/roomMessagePush/${jrmpId}`,
        params: {
          jrmpState: state
        }
      }).then(res => {
        if(state == 1){
          this.$message.success('成功')
        }else{
          this.$message.success('完成')
        }
        this.openDialogMessagePush()
      }).catch(err => {
        this.isError(err,this)
      })
    },
    getBookingOrder(){
      this.bookingOrder.dialogBookingOrder = true
      this.$axios({
        method: 'get',
        url: '/hotel/shortRentContract/getBookingOrder',
        params: this.bookingOrder.params
      }).then(res => {
        this.bookingOrder.dataList = res.data.result.records
        this.bookingOrder.total = res.data.result.total
      }).catch(err => {
        this.isError(err,this)
      })
    },
    bookingOrderCurrent(cursor){
      this.bookingOrder.params.cursor=cursor
      this.getBookingOrder()
    },
    bookingOrderSize(size){
      this.bookingOrder.params.limit=size
      this.getBookingOrder()
    },
    formatAddress(row){
      let a = row.hsAddCommunity ? `${row.hsAddCommunity} ` : ''
      let b = row.hsRoomType ? `${row.hsRoomType} ` : ''
      let c = row.hsAddDoorplateno ? `${row.hsAddDoorplateno}` : ''
      return a + b + c
    },
    formatPopName(row){
      let jsrcPeople = JSON.parse(row.jsrcPeople)
      return jsrcPeople[0].popName
    },
    openBookingOrder(row){
      let obj = {
        diagramJsrcId: row.jsrcId,
        diagramState: row.jsrcState == '预定' || row.jsrcState == '退定中' ? '预' : '留'
      }
      this.order.params.diagramStat = obj.diagramState
      this.orderDetails(obj)
    },
    formatterTime(row){
      return this.DateFormat(row.time, 'YYYY-MM-DD HH:mm:ss')
    },
    formatBeginTime(){
      let nowDay = this.DateFormat(new Date(), 'YYYY-MM-DD')
      this.order.params.jsrcBeginTime = `${nowDay} ${this.order.params.jsrcBeginTime}:00`
      this.calcAmount()
    },
    formatEndTime(){
      let nowDay = this.DateFormat(new Date(), 'YYYY-MM-DD')
      this.order.params.jsrcEndTime = `${nowDay} ${this.order.params.jsrcEndTime}:00`
      this.calcAmount()
    },
    getTimeOptions () {
      //入住时间范围限制
      this.beginTimeOptions = {
        start: JSON.parse(this.setupList.jsrsuHourRoom).hourRoomStartTime,
        step: '01:00',
        end: JSON.parse(this.setupList.jsrsuHourRoom).hourRoomEndTime
      }
      //退房时间范围限制
      let startTime = JSON.parse(this.setupList.jsrsuHourRoom).hourRoomStartTime
      let hourRoom = JSON.parse(this.setupList.jsrsuHourRoom).hourRoom
      let hour = parseInt(startTime.split(':')[0]) + parseInt(hourRoom)
      let start = (hour < 10 ? `0${hour}` : hour) + ':00'
      this.endTImeOptions = {
        start: start,
        step: '01:00',
        end: JSON.parse(this.setupList.jsrsuHourRoom).hourRoomEndTime
      }
      //赋值入住时间和退房时间
      let now = new Date()
      this.order.params.jsrcBeginTime = this.DateFormat(now, `YYYY-MM-DD ${startTime}:00`)
      this.order.params.jsrcEndTime = this.DateFormat(now, `YYYY-MM-DD ${start}:00`)
    },
    makeCard(){
      let vm = this
      vm.offlinePassword = ''
      obj.onResult(function(rData) {
      switch(rData.FunctionID) {
          // case face.FUNCIDS._fid_adaptReader:
          //   alert("Server is ready, you can start now!");
          //   break;
        case face.FUNCIDS._fid_initialcom:
        {
          var rel = rData.RePara_Int;
          if(0 == rel) {
            var hdev = parseInt(rData.RePara_Str);
            if(hdev != -1) {
              clearTimeout(_searchTimer);
              icdev = hdev;
              if(isComOpen == true) {
                  break;
              }
              isComOpen = true; //设置连接状态
              console.log("连接成功");
              Findcard();
            } else {
              console.log("连接失败");
              // _searchTimer = setInterval("Connect()", 3000);
            }
          } else
            alert("连接错误");
          }
          break;
        case face.FUNCIDS._fid_exit:
          alert("连接已关闭");
          break;
        case face.FUNCIDS._fid_findCardStr:
            //读卡响应
        {
          if (controlDeviceNumber === 0){
            var strcard = rData.RePara_Str;
            if(strcard != "") {
              let index = vm.doorCard.doorCardPARM.popIndex
              let endTime = new Date(vm.order.params.jsrcEndTime)
              let beginTime = new Date(vm.order.params.jsrcBeginTime)
              let startTime = new Date()
              let mm = '00'
              if(startTime.getMinutes() >= 0 && startTime.getMinutes() < 20){
                mm = '00'
              }else if(startTime.getMinutes() >= 20 && startTime.getMinutes() < 40){
                mm = '20'
              }else if(startTime.getMinutes() >= 40){
                mm = '40'
              }
              vm.strCard = strcard
              vm.doorCard.doorCardPARM.popName = vm.order.params.popTmpPARMS[index].popName
              vm.doorCard.doorCardPARM.jourDeviceId = vm.doorCard.checkDevice.jourDeviceId
              vm.doorCard.doorCardPARM.jdcHsId = vm.order.params.hsId
              vm.doorCard.doorCardPARM.startTime = vm.DateFormat(startTime, `YYYY-MM-DD HH:${mm}:00`)
              vm.doorCard.doorCardPARM.jdcMaxUnlockingTimes = (endTime.getTime() - beginTime.getTime()) / (1000 * 60 * 60)
              vm.doorCard.doorCardPARM.encryptMode = 1
              vm.doorCard.doorCardPARM.sendMessage = 1
              vm.doorCard.doorCardPARM.authorizeType = 2
              vm.doorCard.doorCardPARM.jdcCardId = strcard
              vm.doorCard.loading = true
              vm.getOfflinePassword902().then(res => {
                res.jdcDeviceId = res.jourDeviceId
                res.popIndex = index
                res.jdcDeadlineTime = vm.DateFormat(endTime, `YYYY-MM-DD HH:mm:ss`)
                vm.order.params.doorCardPARMS.push(res)
                vm.order.params.offlinePassword = res.jdcPassword
                vm.offlinePassword = res.jdcPassword
                vm.doorCard.loading = false
                obj.loadkey(icdev, 0, 9, "FFFFFFFFFFFF")
              })
              obj.beep(icdev, 10);
            } else {
              if(0 == gl_fFailedShow) {
                gl_fFailedShow = 1; //set the flag whether show fail message or not
                alert("找不到卡，请在射频区放一张卡")
              }
              //Findcard();
            }
            controlDeviceNumber++
          }
        }
          break;
        case face.FUNCIDS._fid_halt:
            //卡停止操作响应
          obj.beep(icdev, 10);
          break;
        case face.FUNCIDS._fid_beep:
            //设备哔声响应
          //Findcard();
          //Disconnect();
          break;
        case face.FUNCIDS._fid_loadKey:
            //装载密钥响应
          if (controlDeviceNumber2 === 0){
            if(rData.RePara_Int == 0) {
              console.log("装载成功");
              obj.authentication(icdev, 0, 9);
              vm.cardState = false
            } else {
              console.log("装载失败");
            }
            controlDeviceNumber2++
          }
          break;
        case face.FUNCIDS._fid_authenClass:
            //验证密钥响应
        {
          if (controlDeviceNumber3 === 0){
            var rel = rData.RePara_Int;
            if(0 == rel) {
              console.log("验证扇区密钥成功");
              console.log(vm.offlinePassword)
              let password = vm.offlinePassword
              var str = [];
              for(var i = 0; i < password.length; i++){
                str.push((password.charCodeAt(i)));
              }
              if(password.length == 9){
                str.unshift(9);
              }else if(password.length == 10){
                str.unshift(10);
              }
              var hexCharCode = [];
              for (var i = 0; i < str.length; i++) {
                hexCharCode.push((str[i]).toString(16));
              }
              password = hexCharCode.join("");
              password = "0" + password;
              for(var i = password.length; i < 32; i++){
                password = password + "0";
              }
              console.log(password)
              obj.write(icdev, 36, password);
            } else {
              console.log("验证扇区密钥失败");
            }
            controlDeviceNumber3++
          }
        }
          break;
        case face.FUNCIDS._fid_writeAsHex:
            //写入响应
        {
          var iRet = rData.RePara_Int;
          if(iRet == 0) {
            console.log(vm.order.params.doorCardPARMS)
            vm.$message.success("密码写入成功")
          } else {
            vm.$message.success("密码写入失败")
          }
        }
          break;
        case face.FUNCIDS._fid_writeAsStr:
            //写入响应
        {
          if(controlDeviceNumber4 === 0){
            var iRet = rData.RePara_Int;
            if(iRet == 0) {
              console.log(vm.order.params.doorCardPARMS)
              vm.$message.success("密码写入成功")
              vm.$confirm(`是否继续授权门卡？`, '提示', {
                confirmButtonText: '是',
                cancelButtonText: '否',
                type: 'warning'
              }).then(() => {
              }).catch(() => {
                vm.doorCard.dialogDoorCard = false
              });
            } else {
              vm.$message.success("密码写入失败")
            }
            controlDeviceNumber4++
          }
        }
          break;
      }
      });

      //与读卡器进行连接
      function Connect() {
        try {
          if (isComOpen == false) { //判断是否连接失败
            obj.initialcom(100, 115200)
          }
        } catch(e) {
          alert(e.message);
        }
        return;
      }
      //读卡
      function Findcard() {
        obj.findcardStr(icdev, 0);
      }
      //关闭连接
      function Disconnect() {
        obj.exit(icdev);
        isComOpen = false; //Set unlink status
      }
      var isComOpen = false;

      var gl_fFailedShow = 0;
      var _searchTimer;
      var controlDeviceNumber = 0;
      var controlDeviceNumber2 = 0;
      var controlDeviceNumber3 = 0;
      var controlDeviceNumber4 = 0;
      Connect()
    },
    getOfflinePassword902(){
      return this.$axios({
        method:"post",
        url: '/common2/doorCard/getOfflinPassword902',
        data: this.doorCard.doorCardPARM
      }).then(res=>{
        this.doorCard.loading = false
        return res.data.result
      }).catch(err=>{
        this.doorCard.loading = false
        this.isError(err,this)
      })
    },
    getBirthday(){
      var reg=new RegExp(/(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/)
      if(reg.test(this.order.params.popIdcard)){
        let a=this.order.params.popIdcard.substr(6,4)
        let b=this.order.params.popIdcard.substr(10,2)
        let c=this.order.params.popIdcard.substr(12,2)
        this.order.params.popBirth=`${a}-${b}-${c}`
      }
    },
    // 根据手机号查询会员信息
    memberInformation(val){
      console.log(val)
      this.$axios({
        method: 'get',
        url: '/hotel/shortRentRenter/getRenterByUsername',
        params:{
          username: '1',
          tel:val
        }
      }).then(res => {
        console.log(res)
      }).catch(err => {
        this.isError(err,this)
      })
    },
    querySearch(val,cb){
      this.$axios({
        method: 'get',
        url: '/hotel/shortRentRenter/getRenterByUsername',
        params:{
          username: val,
        }
      }).then(res => {
        console.log(res)
        cb(res.data.result)
      }).catch(err => {
        this.isError(err,this)
      })

    },
    getVipData(ev){
      this.order.params.popName=ev.popName
      this.order.params.popTelephone=ev.popTelephone
      this.order.params.popIdcardType=ev.popIdcardType
      this.order.params.popIdcard=ev.popIdcard
      this.order.params.popBirth=ev.popBirth
      this.order.params.popNation=ev.popNation
      this.order.params.popIdcardAddress=ev.popIdcardAddress
      this.order.params.jsrrCheckInNum=ev.jsrrCheckInNum
    },
    // 修改渠道change事件
    changeChannels(v){
      console.log(v)
      if(v==false){
        if(this.order.params.jcuGroupType==''){
          this.$message({
            message: '请选择群体分类',
            type: 'warning'
          });
        }
      }
    }
  }
}
</script>
<style lang="scss">
.my-autocomplete {
  li{
    line-height: 25px;
    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .addr {
      font-size: 12px;
      color: #b4b4b4;
    }
  }
}
</style>
<style lang="scss">

  .tabs::-webkit-scrollbar{
    display: none;
  }
  .radioGroup .el-radio-button--mini .el-radio-button__inner{
    padding: 7px 10px;
  }


</style>
<style lang="scss" scoped>
  .popName /deep/ .el-autocomplete-suggestion{
    li{
      line-height: 25px
    }
  }
  .screenInput{
    width: 125px;
  }
  .dialog >>> .el-dialog__body{
    height: 486px;
    overflow: auto;
  }
  p{
    margin: 0;
    padding-left: 10px;
    font-size: 16px;
  }
  .list{
    width: 100%;
    // float:left;
    padding-top: 5px;
    .tabs{
      width: 76px;
      float:left;
      overflow: hidden;
      overflow: scroll;
      >>> .el-tabs__item{
        padding: 0 10px;
        color: #606266;
        font-weight: 600;
      }
      >>> .el-tabs__item.is-active {
        color: #409EFF;
      }
    }
    .cardList{
      overflow: scroll;
      .card{
        width: 120px;
        height: 120px;
        margin: 4px;
        float: left;
        position: relative;
        .button{
          font-size: 14px;
          font-weight: 600;
          position: absolute;
          right: 0;
          top: 0;
        }
        .text{
          width: 120px;
          height: 120px;
          cursor:pointer;
          .room_number{
            width: 70px;
            margin: 5px 0 0 5px;
            font-size: 18px;
            overflow: hidden;
            position: absolute;
            left: 0;
            top: 0;
          }
          .describe{
            margin: 0 0 5px 5px;
            font-size: 14px;
            color: #EBEEF5;
            position: absolute;
            left: 0;
            bottom: 0;
          }
        }
      }
    }
  }
  .screen .rightButtonList{
    width: 100%;
    height:168px;
    border-top: 5px solid #e0ecff;
    border-bottom: 5px solid #e0ecff;
    .btn{
      width: 105px;
      padding: 5px;
      margin: 3px;
      border: 0;
    }
  }

</style>